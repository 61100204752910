import React, { Component } from 'react';
import {BrowserRouter as Router} from 'react-router-dom';
import RouteMap from './router';
import { ConfigProvider } from 'antd';
import zhCN from 'antd/es/locale/zh_CN';
import moment from 'moment'
import 'moment/locale/zh-cn'
moment.locale('zh_cn')

class App extends Component {
  render() {
    window.router = this.props.history;
    return (
        <ConfigProvider locale={zhCN}>
            <div className="App">
                <Router>
                    <RouteMap/>
                </Router>
            </div>
        </ConfigProvider>
    );
  }
}

export default App;
