import React from 'react'
import {Row,Col,Button,Modal,Form,Upload,Icon,message,Spin} from 'antd'
import {BaseUrl} from '../../../config'
class CreateModal extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            isLoading:false
        }
    }

    createAccount= (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.props.changeVisible(false,values);
                this.props.form.resetFields();
            }
        });
    }
    cancelCreateAccount(){
        this.props.form.resetFields();
        this.props.changeVisible(false,'');
    }
    onChange(info) {
        this.setState({
            isLoading:true
        })
        if (info.file.status === 'done') {
            message.success(`${info.file.name} 上传成功！`);
            this.props.getVideoUrl(info.file.response.data)
            this.setState({
                isLoading:false
            })
        }else if (info.file.status === 'error') {
            message.error(`${info.file.name} 上传失败！`);
            this.setState({
                isLoading:false
            })
        }
    }
    
    render() {
        const {getFieldDecorator} = this.props.form;
        const video=this.props.video;
        const props = {
            name: 'file',
            action: BaseUrl.fetchUrl+'/file/upload',
            listType: 'picture',
            headers: {
                authorization: 'authorization-text',
            }
          };
        return (
            <Modal
                    maskClosable={false}
                    destroyOnClose={true}
                    visible={this.props.visible}
                    title={"视频"}
                    onOk={this.createAccount.bind(this)}
                    onCancel={this.cancelCreateAccount.bind(this)}
                    footer={[
                        <Button key="back" onClick={this.cancelCreateAccount.bind(this)}>
                        取消
                        </Button>,
                        <Button key="submit" type="primary" onClick={this.createAccount.bind(this)}>
                        确定
                        </Button>,
                    ]}>
                    <Form>
                        <Row>
                            
                            <Col span={24}>
                                <Form.Item
                                    label=""
                                    labelCol={{span: 0}} wrapperCol={{span: 24}}
                                >
                                    {getFieldDecorator('video', {
                                        initialValue: video,
                                        rules: [{
                                            required: true, message: '请上传视频!',
                                        }],
                                    })(
                                        <span style={{display:'none'}}></span>
                                    )}
                                   <Row>
                                        <Col span={24} style={{display:this.state.isLoading?'none':''}}>
                                            <Upload 
                                                {...props}
                                                onChange={this.onChange.bind(this)}
                                            >
                                                <Button>
                                                    <Icon type="upload" /> 上传视频
                                                </Button>
                                            </Upload>
                                            <p>{video}</p>
                                        </Col>
                                        <Col span={24} style={{textAlign:'center'}}>
                                            <Spin spinning={this.state.isLoading}  tip="上传中..."/>
                                        </Col>
                                    </Row>
                                   
                                </Form.Item>
                            </Col> 

                        </Row>
                    </Form>
                    
                </Modal>    
        )
    }
}

export default Form.create({name: 'IndexVideoPage'})(CreateModal); 