import React from 'react'
import {Row, Col,Button,Modal,Input,Radio } from 'antd'


class CreateModal extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            name:'',
        }
    }
    createAccount= (e) => {
        e.preventDefault();
        this.props.changeVisible(false,'submit');
        this.setState({
            name:'',
        })
    }
    cancelCreateAccount(){
        this.props.changeVisible(false,'');
        this.setState({
            name:'',
        })
    }
    
    onChangeResultDate(e) {
        this.setState({
            name:e.target.value
        })
    }
    changeEmployeePermission = e => {
        this.props.changePermissionArr(e.target.value)
    };
    render() {
        const data=this.props.permissionArr;
        const detailData=this.props.detailData;
        return (
            <Modal
                    maskClosable={false}
                    destroyOnClose={true}
                    width={600}
                    visible={this.props.visible}
                    title={"编辑管理员"}
                    onOk={this.createAccount.bind(this)}
                    onCancel={this.cancelCreateAccount.bind(this)}
                    footer={[
                        <Button key="back" onClick={this.cancelCreateAccount.bind(this)}>
                        取消
                        </Button>,
                        <Button key="submit" type="primary" onClick={this.createAccount.bind(this)}>
                        确定
                        </Button>,
                    ]}>
                    <Row>
                        <Col span={24}>
                            <label>账号：</label>
                            <Input value={detailData.userName} disabled  onChange={this.onChangeResultDate.bind(this)} style={{width:'70%'}} size={'small'}/>
                        </Col>
                        <Col span={24} style={{marginTop:'20px'}}>
                            <label>权限管理：</label>
                            <Radio.Group
                                options={data}
                                value={detailData.menu}
                                onChange={this.changeEmployeePermission.bind(this)}
                            />
                        </Col>
                    </Row>
                </Modal>    
        )
    }
}

export default CreateModal;