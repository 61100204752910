import React, {Component} from 'react'
import axios from '../../axios'
import TableGroup from '../../base/table1/index'
import SearchGroup from '../regionManage/searchGroup'
import Title from '../../base/title/index'
import ButtonGroup from '../../base/buttonGroup/index'
import AddModal from '../viewManage/addModal/index'
import ModifyModal from '../viewManage/modifyModal/index'
import {beforeRouterEach} from '../../common/index'
import {BaseUrl} from '../../config'
import {Divider, message, Modal, Icon, Button} from 'antd'

const {confirm} = Modal

class Index extends Component {
    constructor(props, context) {
        super(props, context)
        this.state = {
            btnGroup: [
                {
                    type: 'add',
                    text: '新增',
                },
                {
                    type: 'del',
                    text: '删除',
                },
            ],
            titleMsg: {
                titleName: '交通管理',
            },
            table: {
                hasHeight: true,
                isPageShow: true,
                current: 1,
                totalCount: 0,
                columns: [
                    {
                        title: '排序',
                        dataIndex: 'column',
                        render: (text, record, index) => (
                            <span>
                <span
                    className="tableBtn"
                    onClick={(event) => {
                        this.toTop(record)
                        event.stopPropagation()
                    }}
                >
                  <Button type="link">置顶</Button>
                </span>
                <Divider type="vertical"/>
                <span
                    className="tableBtn"
                    onClick={(event) => {
                        this.seqUp(record, index)
                        event.stopPropagation()
                    }}
                >
                  <Icon type="arrow-up"/>
                </span>
                <Divider type="vertical"/>
                <span
                    className="tableBtn"
                    onClick={(event) => {
                        this.seqDown(record, index)
                        event.stopPropagation()
                    }}
                >
                  <Icon type="arrow-down"/>
                </span>
              </span>
                        ),
                    },
                    {
                        title: '名称',
                        dataIndex: 'zhName',
                    },
                    {
                        title: '标签',
                        dataIndex: 'labelName',
                    },
                    {
                        title: '官方推荐',
                        dataIndex: 'recommend',
                        render: (text, record) => (
                            <span>
                {record.recommend === 1
                    ? '是'
                    : record.recommend === 0
                        ? '否'
                        : ''}
              </span>
                        ),
                    },
                    {
                        title: '操作',
                        key: 'action',
                        render: (text, record) => (
                            <span>
                <span>
                  <span
                      className="tableBtn"
                      onClick={(event) => {
                          this.modify(record)
                          event.stopPropagation()
                      }}
                  >
                    编辑
                  </span>
                  <Divider type="vertical"/>
                  <span
                      className="tableBtn"
                      onClick={(event) => {
                          this.del(record)
                          event.stopPropagation()
                      }}
                  >
                    删除
                  </span>
                </span>
              </span>
                        ),
                    },
                ],
                data: [],
                isSelection: false,
            },
            tableParams: {
                rows: 10,
                page: 1,
                type: 'TRAFFIC',
                zhName: '',
                enName: '',
                label: '',
            },
            addModalVisible: false,
            modifyModalVisible: false,
            detailData: {},
            images: [],
            addInfoData: [],
            tagData: [],
            hotData: [],
            hotDataSelect: [],
            serviceDataSelect: [],
            selected: [],
            nextScroll: 39
        }
    }

    toTop(record) {
        axios.gets('/api/v1/scenic/spot/sticky', {id: record.id}).then((res) => {
            if (res.status === 200) {
                message.success('置顶成功！')
                this.getDataList()
            } else {
                message.error(res.message)
            }
        })
    }

    seqDown(record, index) {
        let length = index + 1
        if (length < this.state.table.data.length) {
            let sort1 = this.state.table.data[length].sort
            let sort2 = record.sort
            record.sort = sort1
            let downRow = this.state.table.data[length]
            downRow.sort = sort2
            axios.posts('/api/v1/scenic/spot/modify', record).then((res) => {
                if (res.status === 200) {
                    axios.posts('/api/v1/scenic/spot/modify', downRow).then((res) => {
                        if (res.status === 200) {
                            message.success('操作成功！')
                            this.getDataList()
                        } else {
                            message.error(res.message)
                        }
                    })
                } else {
                    message.error(res.message)
                }
            })
        }
    }

    seqUp(record, index) {
        if (index > 0) {
            let sort1 = this.state.table.data[index - 1].sort
            let sort2 = record.sort
            record.sort = sort1
            let upRow = this.state.table.data[index - 1]
            upRow.sort = sort2
            axios.posts('/api/v1/scenic/spot/modify', record).then((res) => {
                if (res.status === 200) {
                    axios.posts('/api/v1/scenic/spot/modify', upRow).then((res) => {
                        if (res.status === 200) {
                            message.success('操作成功！')
                            this.getDataList()
                        } else {
                            message.error(res.message)
                        }
                    })
                } else {
                    message.error(res.message)
                }
            })
        }
    }

    whickBtnClicked(val) {
        if (val === 'add') {
            this.setState({
                addModalVisible: true,
            })
        } else if (val === 'del') {
            let _this = this
            let ids = _this.state.selected
            confirm({
                title: '是否删除当前选中标签?',
                content: '',
                onOk() {
                    axios
                        .posts('/api/v1/scenic/spot/remove', {ids: ids})
                        .then((res) => {
                            if (res.status === 200) {
                                message.success('删除成功！')
                                _this.getDataList()
                                _this.getHotData()
                                _this.setState({
                                    selected: [],
                                })
                            } else {
                                message.error(res.message)
                            }
                        })
                },
                onCancel() {
                    console.log('Cancel')
                },
            })
        }
    }

    del(record) {
        let _this = this
        confirm({
            title: '是否删除当前景点?',
            content: '',
            onOk() {
                axios
                    .posts('/api/v1/scenic/spot/remove', {ids: [record.id]})
                    .then((res) => {
                        if (res.status === 200) {
                            message.success('删除成功！')
                            _this.getDataList()
                            _this.getHotData()
                        } else {
                            message.error(res.message)
                        }
                    })
            },
            onCancel() {
                console.log('Cancel')
            },
        })
    }

    getHotData() {
        axios
            .gets('/api/v1/scenic/spot/find', {page: 1, rows: 100})
            .then((res) => {
                if (res.status === 200) {
                    let data = []
                    res.data.records.map((item, index) => {
                        data.push({
                            label: item.zhName,
                            value: item.id,
                        })
                        return item
                    })
                    this.setState({
                        hotData: data,
                    })
                } else {
                    message.error(res.message)
                }
            })
    }

    modify(record) {
        axios.gets('/api/v1/scenic/spot/detail', {id: record.id}).then((res) => {
            if (res.status === 200) {
                res.data.recommend = res.data.recommend.toString()
                res.data.label = res.data.label.split(',')
                let label = []
                res.data.label.map((item, index) => {
                    label.push(parseInt(item))
                    return item
                })
                res.data.label = label
                this.setState({
                    images: res.data.photo.split(','),
                })
                let hot = []
                if (res.data.hot !== null) {
                    res.data.hot = JSON.parse(res.data.hot)
                    res.data.hot.map((item, index) => {
                        hot.push({
                            value: item.id,
                            label: item.label,
                        })
                        return item
                    })
                }
                let service = []
                if (res.data.service !== null) {
                    res.data.service = JSON.parse(res.data.service)
                    res.data.service.map((item, index) => {
                        service.push({
                            value: item.id,
                            label: item.label,
                        })
                        return item
                    })
                }

                if (res.data.message !== null) {
                    res.data.message = JSON.parse(res.data.message)
                } else {
                    res.data.message = []
                }
                this.setState(
                    {
                        hotDataSelect: hot,
                        serviceDataSelect: service,
                        detailData: res.data,
                        addInfoData: res.data.message,
                    },
                    () => {
                        this.setState({
                            modifyModalVisible: true,
                        })
                    }
                )
            } else {
                message.error(res.message)
            }
        })
    }

    changeAddModal(val, values) {
        if (values !== '') {
            let data = {}
            if (values.latlng) {
                data = {
                    type: 'TRAFFIC',
                    zhName: values.zhName,
                    enName: values.enName,
                    content: values.content,
                    label: values.label.join(','),
                    photo: values.photo,
                    recommend: parseInt(values.recommend),
                    message: JSON.stringify(this.state.addInfoData),
                    lat: values.latlng.split(',')[0],
                    lng: values.latlng.split(',')[1],
                    address: values.address,
                }
            } else {
                data = {
                    type: 'TRAFFIC',
                    zhName: values.zhName,
                    enName: values.enName,
                    content: values.content,
                    label: values.label.join(','),
                    photo: values.photo,
                    recommend: parseInt(values.recommend),
                    message: JSON.stringify(this.state.addInfoData),
                    address: values.address,
                }
            }
            if (
                typeof values.comment === 'undefined' ||
                typeof values.comment === undefined ||
                values.comment === ''
            ) {
                data.comment = ''
            } else {
                data.comment = BaseUrl.fetchUrl + values.comment
            }
            if (
                typeof values.phone === 'undefined' ||
                typeof values.phone === undefined ||
                values.phone === ''
            ) {
                data.phone = ''
            } else {
                data.phone = values.phone
            }
            if (
                typeof values.website === 'undefined' ||
                typeof values.website === undefined ||
                values.website === ''
            ) {
                data.website = ''
            } else {
                data.website = values.website
            }
            if (this.state.hotDataSelect.length > 0) {
                let hot = []
                this.state.hotDataSelect.map((item, index) => {
                    hot.push({
                        id: item.id,
                        label: item.label,
                    })
                    return item
                })
                hot = JSON.stringify(hot)
                data.hot = hot
            }
            if (this.state.serviceDataSelect.length > 0) {
                let service = []
                this.state.serviceDataSelect.map((item, index) => {
                    service.push({
                        id: item.id,
                        label: item.label,
                    })
                    return item
                })
                service = JSON.stringify(service)
                data.service = service
            }
            axios.posts('/api/v1/scenic/spot/insert', data).then((res) => {
                if (res.status === 200) {
                    message.success('新增成功！')
                    this.getDataList()
                    this.getHotData()
                    this.setState({
                        addInfoData: [],
                        hotDataSelect: [],
                        serviceDataSelect: [],
                        images: [],
                    })
                } else {
                    message.error(res.message)
                    this.setState({
                        addInfoData: [],
                        hotDataSelect: [],
                        serviceDataSelect: [],
                        images: [],
                    })
                }
            })
        }
        this.setState({
            addModalVisible: val,
            addInfoData: [],
            hotDataSelect: [],
            serviceDataSelect: [],
            images: [],
        })
    }

    changeModifyModal(val, values) {
        if (values !== '') {
            let data = {
                zhName: values.zhName,
                enName: values.enName,
                phone: values.phone,
                website: values.website,
                content: values.content,
                label: values.label.join(','),
                photo: values.photo,
                recommend: parseInt(values.recommend),
                message: JSON.stringify(this.state.addInfoData),
                lat: values.latlng.split(',')[0],
                lng: values.latlng.split(',')[1],
                id: this.state.detailData.id,
                address: values.address,
            }
            if (
                typeof values.comment === 'undefined' ||
                typeof values.comment === undefined ||
                values.comment === ''
            ) {
                data.comment = ''
            } else {
                if (values.comment.indexOf('http') < 0) {
                    data.comment = BaseUrl.fetchUrl + values.comment
                } else {
                    data.comment = values.comment
                }
            }
            if (this.state.hotDataSelect.length > 0) {
                let hot = []
                let serviceData = this.state.hotDataSelect
                serviceData.map((item, index) => {
                    this.state.hotData.map((items, indexs) => {
                        if (item.label === items.label) {
                            item.id = items.value
                        }
                        return items
                    })
                    return item
                })
                serviceData.map((item, index) => {
                    hot.push({
                        id: item.id,
                        label: item.label,
                    })
                    return item
                })
                hot = JSON.stringify(hot)
                data.hot = hot
            }
            if (this.state.serviceDataSelect.length > 0) {
                let service = []
                let serviceData = this.state.serviceDataSelect
                serviceData.map((item, index) => {
                    this.state.hotData.map((items, indexs) => {
                        if (item.label === items.label) {
                            item.id = items.value
                        }
                        return items
                    })
                    return item
                })
                serviceData.map((item, index) => {
                    service.push({
                        id: item.id,
                        label: item.label,
                    })
                    return item
                })
                service = JSON.stringify(service)
                data.service = service
            }
            axios.posts('/api/v1/scenic/spot/modify', data).then((res) => {
                if (res.status === 200) {
                    message.success('编辑成功！')
                    this.getDataList()
                    this.getHotData()
                    this.setState({
                        addInfoData: [],
                        hotDataSelect: [],
                        serviceDataSelect: [],
                        images: [],
                    })
                } else {
                    message.error(res.message)
                    this.setState({
                        addInfoData: [],
                        hotDataSelect: [],
                        serviceDataSelect: [],
                        images: [],
                    })
                }
            })
        }
        this.setState({
            modifyModalVisible: val,
            addInfoData: [],
            hotDataSelect: [],
            serviceDataSelect: [],
            images: [],
        })
    }

    getDataList() {
        axios
            .gets('/api/v1/scenic/spot/find', this.state.tableParams)
            .then((res) => {
                if (res.status === 200) {
                    let data = Object.assign({}, this.state.table, {
                        data: res.data.records,
                        totalCount: res.data.total,
                    })
                    this.setState({
                        table: data,
                    })
                } else {
                    message.error(res.message)
                }
            })
    }

    selectedChange(val) {
        this.setState({
            selected: val,
        })
    }

    tableSizeChange(current, pageSize) {
        let data = Object.assign({}, this.state.table, {
            current: current,
        })
        this.setState({
            table: data,
        })
        let data2 = Object.assign({}, this.state.tableParams, {
            rows: pageSize,
        })
        this.setState(
            {
                tableParams: data2,
            },
            () => {
                this.getDataList()
            }
        )
    }

    tablePageChange(page) {
        let data = Object.assign({}, this.state.table, {
            current: page,
        })
        this.setState({
            table: data,
        })
        let data2 = Object.assign({}, this.state.tableParams, {
            page: page,
        })
        this.setState(
            {
                tableParams: data2,
            },
            () => {
                this.getDataList()
            }
        )
    }

    resultDateSearch(value) {
        let data = Object.assign({}, this.state.table, {
            current: 1,
        })
        this.setState({
            table: data,
        })
        let data2 = Object.assign({}, this.state.tableParams, {
            rows: 10,
            page: 1,
            zhName: value.toString(),
            enName: value.toString(),
        })
        this.setState({
            tableParams: data2,
        })
    }

    resetChange() {
        let data = Object.assign({}, this.state.table, {
            current: 1,
        })
        this.setState({
            table: data,
        })
        let data2 = Object.assign({}, this.state.tableParams, {
            rows: 10,
            page: 1,
            zhName: '',
            enName: '',
            label: '',
        })
        this.setState(
            {
                tableParams: data2,
            },
            () => {
                this.getDataList()
            }
        )
    }

    searchList() {
        this.getDataList()
    }

    getImage(val) {
        let data = this.state.images
        data.push(BaseUrl.fetchUrl + val)
        this.setState({
            images: data,
        })
    }

    delImage(val) {
        let data = this.state.images
        data = data.filter(function (item, index) {
            return index !== val
        })

        this.setState({
            images: data,
        })
    }

    optionsChangeSex(value) {
        let data = Object.assign({}, this.state.table, {
            current: 1,
        })
        this.setState({
            table: data,
        })
        let data2 = Object.assign({}, this.state.tableParams, {
            rows: 10,
            page: 1,
            label: value.toString(),
        })
        this.setState({
            tableParams: data2,
        })
    }

    componentDidMount() {
        beforeRouterEach(this)
        this.getDataList()
        this.getTag()
        this.getHotData()
        const tableBox = document.getElementById('table-box')
        tableBox.addEventListener('scroll', this.debounce(() => {
            if (tableBox.scrollTop>=this.state.nextScroll) {
                let obj=this.state.tableParams;
                obj.rows+=20;

                axios
                    .gets('/api/v1/scenic/spot/find', this.state.tableParams)
                    .then((res) => {
                        if (res.status === 200) {
                            let data = Object.assign({}, this.state.table, {
                                data: res.data.records,
                                totalCount: res.data.total,
                            })

                            this.setState({
                                table: data,
                            })

                            let nextScroll=this.state.nextScroll*2;

                            this.setState({
                                nextScroll,
                                tableParams:obj
                            })

                            message.success('加载成功');
                        } else {
                            message.error(res.message)
                        }
                    })
            }
        },500))
    }

    debounce(fn,wait){
        var timer = null;
        return function(){
            if(timer !== null){
                clearTimeout(timer);
            }
            timer = setTimeout(fn,wait);
        }
    }

    getTag() {
        axios
            .gets('/api/v1/label/find', {
                rows: 100,
                page: 1,
                type: 'TRAFFIC',
            })
            .then((res) => {
                if (res.status === 200) {
                    this.setState({
                        tagData: res.data.records,
                    })
                } else {
                    message.error(res.message)
                }
            })
    }

    changeInfoVisible(val) {
        if (val !== '') {
            let data = this.state.addInfoData
            data.push(val)
            data.map((item, index) => {
                item.key = index
                return item
            })
            this.setState({
                addInfoData: data,
            })
        }
    }

    changeModifyInfoVisible(val) {
        if (val !== '') {
            let data = this.state.addInfoData
            data.map((item, index) => {
                if (item.key === val.key) {
                    item.viewTitle = val.viewTitle
                    item.viewContent = val.viewContent
                }
                return item
            })
            this.setState({
                addInfoData: data,
            })
        }
    }

    changeDelInfoVisible(val) {
        let data = this.state.addInfoData
        data = data.filter(function (item, index) {
            return index !== val
        })
        this.setState({
            addInfoData: data,
        })
    }

    changeHot(val) {
        let data = []
        val.map((item, index) => {
            this.state.hotData.map((items, indexs) => {
                if (item === items.value) {
                    data.push({
                        id: items.value,
                        label: items.label,
                    })
                }
                return items
            })
            return item
        })
        this.setState({
            hotDataSelect: data,
        })
    }

    changeService(val) {
        let data = []
        val.map((item, index) => {
            this.state.hotData.map((items, indexs) => {
                if (item === items.value) {
                    data.push({
                        id: items.value,
                        label: items.label,
                    })
                }
                return items
            })
            return item
        })
        this.setState({
            serviceDataSelect: data,
        })
    }

    delTag(val) {
        let data = this.state.hotDataSelect
        data = data.filter(function (item, index) {
            return index !== val
        })

        this.setState({
            hotDataSelect: data,
        })
    }

    delTagService(val) {
        let data = this.state.serviceDataSelect
        data = data.filter(function (item, index) {
            return index !== val
        })

        this.setState({
            serviceDataSelect: data,
        })
    }

    render() {
        return (
            <div className="regionManage">
                <Title titleMsg={this.state.titleMsg}/>
                <SearchGroup
                    tagData={this.state.tagData}
                    resetChange={this.resetChange.bind(this)}
                    resultDateSearch={this.resultDateSearch.bind(this)}
                    optionsChangeSex={this.optionsChangeSex.bind(this)}
                    searchList={this.searchList.bind(this)}
                />
                <ButtonGroup
                    whichClick={this.whickBtnClicked.bind(this)}
                    btnGroup={this.state.btnGroup}
                />
                <TableGroup
                    selectedChange={this.selectedChange.bind(this)}
                    pageChange={this.tablePageChange.bind(this)}
                    sizeChange={this.tableSizeChange.bind(this)}
                    table={this.state.table}
                />
                <AddModal
                    changeService={this.changeService.bind(this)}
                    changeHot={this.changeHot.bind(this)}
                    hotDataSelect={this.state.hotDataSelect}
                    serviceDataSelect={this.state.serviceDataSelect}
                    delTag={this.delTag.bind(this)}
                    delTagService={this.delTagService.bind(this)}
                    hotData={this.state.hotData}
                    tagData={this.state.tagData}
                    changeDelInfoVisible={this.changeDelInfoVisible.bind(this)}
                    changeInfoVisible={this.changeInfoVisible.bind(this)}
                    changeModifyInfoVisible={this.changeModifyInfoVisible.bind(this)}
                    addInfoData={this.state.addInfoData}
                    images={this.state.images}
                    getImage={this.getImage.bind(this)}
                    delImage={this.delImage.bind(this)}
                    changeVisible={this.changeAddModal.bind(this)}
                    visible={this.state.addModalVisible}
                />
                <ModifyModal
                    detailData={this.state.detailData}
                    changeService={this.changeService.bind(this)}
                    changeHot={this.changeHot.bind(this)}
                    hotDataSelect={this.state.hotDataSelect}
                    serviceDataSelect={this.state.serviceDataSelect}
                    delTag={this.delTag.bind(this)}
                    delTagService={this.delTagService.bind(this)}
                    hotData={this.state.hotData}
                    tagData={this.state.tagData}
                    changeDelInfoVisible={this.changeDelInfoVisible.bind(this)}
                    changeInfoVisible={this.changeInfoVisible.bind(this)}
                    changeModifyInfoVisible={this.changeModifyInfoVisible.bind(this)}
                    addInfoData={this.state.addInfoData}
                    images={this.state.images}
                    getImage={this.getImage.bind(this)}
                    delImage={this.delImage.bind(this)}
                    changeVisible={this.changeModifyModal.bind(this)}
                    visible={this.state.modifyModalVisible}
                />
            </div>
        )
    }
}

export default Index
