import React from 'react'
import {Row, Col,  Button} from 'antd'

class BtnGroup extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
        }
    }
    clickBtn(val){
        this.props.whichClick(val)
    }
    render() {
        const size=this.props.size
        return (
            <Row style={{padding: '10px 20px '}}>
                <Col span={24}>
                    
                        {
                            this.props.btnGroup.map((item,index)=>{
                                return <Button 
                                            style={{marginRight:'10px',marginBottom:'5px'}}
                                            size={size?size:'default'}
                                            key={index} 
                                            type="primary" 
                                            onClick={this.clickBtn.bind(this,item.type)} >{item.text}</Button>        
                            })
                        }
                    
                </Col>
            </Row>
        )
    }
}

export default BtnGroup