import React, {Component} from 'react';
import {Input, message} from 'antd';
import axios from '../axios/index';
import {BaseUrl} from '../config'
import './login.css';

class Login extends Component {
    constructor(props,context){
        super(props,context);
        this.state={
            height:0,
            userName:'',
            passWord:'',
            code:'',
            codeUrl:'',
            img:""
        }
    }
    handleChangeUser(event){
        this.setState({userName: event.target.value});
    }
    handleChangePsw(event){
        this.setState({passWord: event.target.value});
    }
    handleChangeCode(e){
        this.setState({code: e.target.value});
    }
    submit(){
        if(this.state.userName===''||this.state.passWord===''){
            message.error('请检查用户名、密码是否填写！');
        }else{
            let _this=this;
            window.localStorage.setItem('user_name',_this.state.userName);
            window.localStorage.setItem('pass_word',_this.state.passWord);
            axios.posts('/api/v1/user/login',{
                'userName':_this.state.userName,
                'passWord':_this.state.passWord,
                'code':_this.state.code
            }).then((res)=>{
                if(res.status===200){
                    window.sessionStorage.setItem('token',res.data.userToken.token);
                    window.sessionStorage.setItem('menu',res.data.userMenu.content);
                    window.sessionStorage.setItem('userName',res.data.userName);
                    window.sessionStorage.setItem('userId',res.data.id);
                    window.sessionStorage.setItem('password',res.data.passWord);
                    _this.props.history.push("/index/userAdminManage")
                }else{
                    message.error(res.message)
                }
                
            })
            
        }

    }
    handleKeyDown(e){
        // if(window.sessionStorage.getItem('isLogin')===null||window.sessionStorage.getItem('isLogin')===''){
        //     if(e.keyCode===13){
        //         this.submit();
        //     }
        // }
    }
    reFresh(){
        this.getCode()
    } 
    getCode(){
        this.setState({codeUrl:BaseUrl.fetchUrl+`/api/v1/user/code?${Math.random()}`});
    }
    componentDidMount(){
        const screenHeight=document.documentElement.clientHeight;
        this.setState({
            height:screenHeight
        })
        document.addEventListener('keyup', this.handleKeyDown.bind(this));
        this.getCode()
        this.getImg()
    }
    getImg(){
        axios.gets('/api/v1/index/find',{page:1,rows:1})
            .then((res)=>{
                if(res.status===200){
                    if(res.data.records.length>0){
                        this.setState({
                            img:res.data.records[0].logo
                        })
                    }
                    
                }else{
                    message.error(res.message)
                }
            })
    }
    render() {
        return (
            <div className="Login" style={{height: this.state.height,backgroundImage:'url('+ this.state.img +')',backgroundRepeat:'no-repeat',backgroundSize:'100% 100%'}}>
                   
                    <div className="systemBox">
                        <div className="inputBox" style={{fontSize:'24px',fontWeight:'800',color:'rgb(30,40,84)'}}>
                            用户登录
                        </div>
                        <div className="inputBox">
                            <Input  placeholder="用户名" value={this.state.userName} onChange={this.handleChangeUser.bind(this)}></Input>
                        </div>
                        <div className="inputBox">
                            <Input  placeholder="密码" type="password" value={this.state.passWord} onChange={this.handleChangePsw.bind(this)}></Input>
                        </div>
                        <div className="inputBox" style={{textAlign:'left'}}>
                            <Input placeholder="验证码" style={{width:'60%',marginRight:'10px'}}  value={this.state.code} onChange={this.handleChangeCode.bind(this)}></Input>
                            <img style={{cursor:'pointer'}} src={this.state.codeUrl} onClick={this.reFresh.bind(this)} alt="" />
                        </div>
                        <div className="inputBox" 
                            onClick={this.submit.bind(this)} 
                            id="loginBtn" 
                            style={{cursor:'pointer',background:'rgb(30,40,84)',height:'42px',lineHeight:'42px',color:'#fff',fontSize:'18px',fontWeight:'600'}}>
                           登录
                        </div>
                    </div>
            </div>
        );
    }
}

export default Login;