import React from 'react'
import { Row, Col, Button, Modal, Checkbox } from 'antd'

class CreateModal extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            indeterminate: true,
            checkAll: false,
        }
    }

    createAccount = (e) => {
        e.preventDefault();
        this.props.changeVisible(false, 'submit');
    }
    cancelCreateAccount() {
        this.props.changeVisible(false, '');
    }

    onChange(checkedValues) {
        this.props.changeService(checkedValues)
    }

    onCheckAllChange(e) {
        this.setState({
            indeterminate: false,
            checkAll: e.target.checked,
        });
        e.target.checked ? this.props.isCheckAll(true) : this.props.isCheckAll(false)
    };
    render() {
        let options = this.props.data
        const currentId = this.props.currentId
        options = options.filter(item => item.value !== currentId)
        let hotDataSelected = this.props.hotDataSelected
        return (
            <Modal
                maskClosable={false}
                destroyOnClose={true}
                visible={this.props.visible}
                title={"地图周边"}
                onOk={this.createAccount.bind(this)}
                onCancel={this.cancelCreateAccount.bind(this)}
                footer={[
                    <Button key="back" onClick={this.cancelCreateAccount.bind(this)}>
                        取消
                        </Button>,
                    <Button key="submit" type="primary" onClick={this.createAccount.bind(this)}>
                        确定
                        </Button>,
                ]}>

                <Row>
                    <Col span={24}>
                        <Checkbox
                            indeterminate={this.state.indeterminate}
                            onChange={this.onCheckAllChange.bind(this)}
                            checked={this.state.checkAll}
                        >
                            全选
                        </Checkbox>
                    </Col>
                    <Col span={24}>
                        <Checkbox.Group
                            value={hotDataSelected}
                            options={options}
                            onChange={this.onChange.bind(this)} />
                    </Col>
                </Row>


            </Modal>
        )
    }
}

export default CreateModal;