import React from 'react'
import { Row, Col, Button, Input, Select } from 'antd'
const { Option } = Select

class SearchGroup extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            scenicSpotZhName: '',
            asc: 'false'
        }
    }

    onChangeResultName(e) {
        this.setState({
            scenicSpotZhName: e.target.value
        })
        this.props.onChangeResultName(e.target.value)
    }
    optionsChangeSex(value) {
        this.setState({
            asc: value
        })
        this.props.optionsChangeSex(value)
    }
    searchList() {
        this.props.searchList()
    }
    reset() {
        this.setState({
            scenicSpotZhName: '',
            asc: 'false'
        })
        this.props.resetChange()
    }

    render() {
        return (
            <Row style={{ padding: '10px 20px' }}>
                <Col xl={6} lg={6} md={6}>
                    <label style={{ marginRight: '5px' }}>名称：</label>
                    <Input style={{ width: '50%' }} size={'small'} value={this.state.scenicSpotZhName} onChange={this.onChangeResultName.bind(this)} />
                </Col>
                <Col xl={5} lg={5} md={5}>
                    <label style={{ marginRight: '5px' }}>排序：</label>
                    <Select
                        style={{ width: '50%' }}
                        size={'small'}
                        value={this.state.asc} onChange={this.optionsChangeSex.bind(this)}
                    >
                        <Option value="false">降序</Option>
                        <Option value="true">升序</Option>
                    </Select>

                </Col>
                <Col xl={5} lg={5} md={5}>
                    <Button type="primary" onClick={this.searchList.bind(this)} style={{ marginRight: '10px' }}>查询</Button>
                    <Button onClick={this.reset.bind(this)}>重置</Button>
                </Col>
            </Row>
        )
    }
}

export default SearchGroup