import React, { Component } from 'react';
import axios from '../../axios'
import { beforeRouterEach, deepCopy } from '../../common/index'
import { BaseUrl } from '../../config'
import TableGroup from '../../base/table1/index'
import SearchGroup from './searchGroup'
import Title from '../../base/title/index'
import ButtonGroup from '../../base/buttonGroup/index'
import AddModal from './addModal/index'
import ModifyModal from './modifyModal/index'
import { Divider, message, Modal, Icon, Button } from 'antd';
const { confirm } = Modal;

class Index extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            btnGroup: [
                {
                    type: 'add',
                    text: '新增'
                },
                {
                    type: 'del',
                    text: '删除'
                },
            ],
            titleMsg: {
                titleName: '旅行路线管理',
            },
            table: {
                hasHeight: true,
                isPageShow: true,
                current: 1,
                totalCount: 0,
                columns: [
                    {
                        title: '排序',
                        dataIndex: 'column',
                        render: (text, record, index) => (
                            <span>
                                <span className='tableBtn' onClick={(event) => {
                                    this.toTop(record)
                                    event.stopPropagation()
                                }}><Button type="link">置顶</Button></span><Divider type="vertical" />
                                <span className='tableBtn' onClick={(event) => {
                                    this.seqUp(record, index)
                                    event.stopPropagation()
                                }}><Icon type="arrow-up" /></span><Divider type="vertical" />
                                <span className='tableBtn' onClick={(event) => {
                                    this.seqDown(record, index)
                                    event.stopPropagation()
                                }}><Icon type="arrow-down" /></span>
                            </span>
                        ),
                    },
                    {
                        title: '路线名称',
                        dataIndex: 'name',
                    },
                    // {
                    //     title: '路线分类',
                    //     dataIndex: 'type',
                    //     render: (text, record) => (
                    //         <span>
                    //             {record.type === 'SELF_DRIVE' ? '自驾路线' : record.type === 'CLASSIC' ? '经典路线' : ''}
                    //         </span>
                    //     ),
                    // },
                    {
                        title: '路线分类',
                        dataIndex: 'labelName',
                    }, {
                        title: '热门路线',
                        dataIndex: 'hot',
                        render: (text, record) => (
                            <span>
                                {record.hot === 1 ? '是' : record.hot === 0 ? '否' : ''}
                            </span>
                        ),
                    },
                    {
                        title: '操作',
                        key: 'action',
                        render: (text, record) => (
                            <span>
                                <span>
                                    <span className='tableBtn' onClick={(event) => {
                                        this.modify(record)
                                        event.stopPropagation()
                                    }}>编辑</span><Divider type="vertical" />
                                    <span className='tableBtn' onClick={(event) => {
                                        this.del(record)
                                        event.stopPropagation()
                                    }}>删除</span>


                                </span>
                            </span>
                        ),
                    }
                ],
                data: [],
                isSelection: false
            },
            tableParams: {
                rows: 10,
                page: 1,
                name: '',
                label: '',
                type: ''
            },
            addModalVisible: false,
            modifyModalVisible: false,
            detailData: {

            },
            images: [],
            addInfoData: [],
            tagData: [],
            selected: [],
            tripData: [],
            spotData: [],
            dayData: [{
                value: 1,
                name: '第1天'
            }],
            dayDataModify: [
                {
                    value: 1,
                    name: '第1天'
                }
            ],
            typeData: [
                {
                    id: 'CLASSIC',
                    name: '经典路线'
                },
                {
                    id: 'SELF_DRIVE',
                    name: '自驾路线'
                }
            ],
            nextScroll: 39
        }
    }
    whickBtnClicked(val) {
        if (val === 'add') {
            this.setState({
                addModalVisible: true
            })
        } else if (val === 'del') {
            let _this = this;
            let ids = _this.state.selected;
            confirm({
                title: '是否删除当前选中路线?',
                content: '',
                onOk() {
                    axios.posts('/api/v1/route/remove', { ids: ids })
                        .then((res) => {
                            if (res.status === 200) {
                                message.success('删除成功！')
                                _this.getDataList()
                                _this.setState({
                                    selected: []
                                })
                            } else {
                                message.error(res.message)
                            }
                        })
                },
                onCancel() {
                    console.log('Cancel');
                },
            });
        }
    }
    del(record) {
        let _this = this;
        confirm({
            title: '是否删除当前路线?',
            content: '',
            onOk() {
                axios.posts('/api/v1/route/remove', { ids: [record.id] })
                    .then((res) => {
                        if (res.status === 200) {
                            message.success('删除成功！')
                            _this.getDataList()
                        } else {
                            message.error(res.message)
                        }
                    })
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }
    toTop(record) {
        axios.gets('/api/v1/route/sticky', { id: record.id })
            .then((res) => {
                if (res.status === 200) {
                    message.success('置顶成功！')
                    this.getDataList()
                } else {
                    message.error(res.message)
                }
            })
    }
    seqDown(record, index) {
        let length = index + 1;
        if (length < this.state.table.data.length) {
            let sort1 = this.state.table.data[length].sort;
            let sort2 = record.sort;
            record.sort = sort1;
            let downRow = this.state.table.data[length];
            downRow.sort = sort2;
            axios.posts('/api/v1/route/modify', record)
                .then((res) => {
                    if (res.status === 200) {
                        axios.posts('/api/v1/route/modify', downRow)
                            .then((res) => {
                                if (res.status === 200) {
                                    message.success('操作成功！')
                                    this.getDataList()
                                } else {
                                    message.error(res.message)
                                }
                            })
                    } else {
                        message.error(res.message)
                    }
                })
        }

    }
    seqUp(record, index) {
        if (index > 0) {
            let sort1 = this.state.table.data[index - 1].sort;
            let sort2 = record.sort;
            record.sort = sort1;
            let upRow = this.state.table.data[index - 1];
            upRow.sort = sort2;
            axios.posts('/api/v1/route/modify', record)
                .then((res) => {
                    if (res.status === 200) {
                        axios.posts('/api/v1/route/modify', upRow)
                            .then((res) => {
                                if (res.status === 200) {
                                    message.success('操作成功！')
                                    this.getDataList()
                                } else {
                                    message.error(res.message)
                                }
                            })
                    } else {
                        message.error(res.message)
                    }
                })
        }
    }
    modify(record) {
        axios.gets('/api/v1/route/detail', { id: record.id })
            .then((res) => {
                if (res.status === 200) {
                    res.data.label = parseInt(res.data.label)
                    res.data.day = res.data.day.toString()
                    res.data.number = res.data.number.toString()
                    res.data.hot = res.data.hot.toString()
                    this.setState({
                        images: res.data.photo.split(','),
                    })
                    let temp = Number(res.data.day);
                    let dayData = []
                    for (let i = 0; i < temp; i++) {
                        let num = i + 1
                        dayData.push({
                            name: '第' + num + '天',
                            value: num
                        })
                    }
                    this.setState({
                        dayDataModify: dayData
                    })
                    if (res.data.trip !== null) {
                        res.data.trip = JSON.parse(res.data.trip);
                        this.setState({
                            tripData: res.data.trip
                        })
                    }
                    this.setState({
                        detailData: res.data
                    }, () => {
                        this.setState({
                            modifyModalVisible: true
                        })
                    })

                } else {
                    message.error(res.message)
                }
            })

    }
    changeAddModal(val, values) {
        if (values !== '') {
            let data = {
                name: values.name,
                type: values.type,
                remark: values.remark,
                label: values.label.toString(),
                photo: values.photo,
                day: parseInt(values.day),
                number: parseInt(values.number),
                hot: parseInt(values.hot),
            }
            if (this.state.tripData.length > 0) {
                let trip = ''
                trip = JSON.stringify(this.state.tripData)
                data.trip = trip
            }

            axios.posts('/api/v1/route/insert', data)
                .then((res) => {
                    if (res.status === 200) {
                        message.success('新增成功！')
                        this.getDataList()
                        this.setState({
                            tripData: [],
                            images: []
                        })
                    } else {
                        message.error(res.message)
                        this.setState({
                            tripData: [],
                            images: []
                        })
                    }
                })
        }
        this.setState({
            addModalVisible: val,
            tripData: [],
            images: []
        })
    }
    changeModifyModal(val, values) {

        if (values !== '') {
            let data = {
                name: values.name,
                remark: values.remark,
                label: values.label.toString(),
                photo: values.photo,
                day: parseInt(values.day),
                number: parseInt(values.number),
                hot: parseInt(values.hot),
                id: this.state.detailData.id
            }
            if (this.state.tripData.length > 0) {
                let trip = ''
                trip = JSON.stringify(this.state.tripData)
                data.trip = trip
            }
            axios.posts('/api/v1/route/modify', data)
                .then((res) => {
                    if (res.status === 200) {
                        message.success('编辑成功！')
                        this.getDataList()
                        this.getDataList()
                        this.setState({
                            tripData: [],
                            images: []
                        })
                    } else {
                        message.error(res.message)
                        this.setState({
                            tripData: [],
                            images: []
                        })
                    }
                })
        }
        this.setState({
            modifyModalVisible: val,
            tripData: [],
            images: []
        })
    }
    getDataList() {
        axios.gets('/api/v1/route/find', this.state.tableParams)
            .then((res) => {
                if (res.status === 200) {
                    let data = Object.assign({}, this.state.table, {
                        data: res.data.records,
                        totalCount: res.data.total,
                    });
                    this.setState({
                        table: data
                    })
                } else {
                    message.error(res.message)
                }
            })
    }
    selectedChange(val) {
        this.setState({
            selected: val
        })
    }
    tableSizeChange(current, pageSize) {
        let data = Object.assign({}, this.state.table, {
            current: current,
        });
        this.setState({
            table: data
        });
        let data2 = Object.assign({}, this.state.tableParams, {
            rows: pageSize,
        });
        this.setState({
            tableParams: data2
        }, () => {
            this.getDataList();
        });

    }
    tablePageChange(page) {
        let data = Object.assign({}, this.state.table, {
            current: page,
        });
        this.setState({
            table: data
        });
        let data2 = Object.assign({}, this.state.tableParams, {
            page: page,
        });
        this.setState({
            tableParams: data2
        }, () => {
            this.getDataList();
        });
    }
    resultDateSearch(value) {
        let data = Object.assign({}, this.state.table, {
            current: 1,
        });
        this.setState({
            table: data
        });
        let data2 = Object.assign({}, this.state.tableParams, {
            rows: 10,
            page: 1,
            name: value
        });
        this.setState({
            tableParams: data2
        });
    }
    resetChange() {
        let data = Object.assign({}, this.state.table, {
            current: 1,
        });
        this.setState({
            table: data
        });
        let data2 = Object.assign({}, this.state.tableParams, {
            rows: 10,
            page: 1,
            name: '',
            label: '',
            type: ''
        });
        this.setState({
            tableParams: data2
        }, () => {
            this.getDataList();
        });
    }
    searchList() {
        this.getDataList();
    }
    getImage(val) {
        let data = this.state.images;
        data.push(BaseUrl.fetchUrl + val)
        this.setState({
            images: data
        })
    }
    delImage(val) {
        let data = this.state.images;
        data = data.filter(function (item, index) {
            return index !== val
        });

        this.setState({
            images: data
        })
    }
    optionsChangeSex(value) {
        let data = Object.assign({}, this.state.table, {
            current: 1,
        });
        this.setState({
            table: data
        });
        let data2 = Object.assign({}, this.state.tableParams, {
            rows: 10,
            page: 1,
            label: value.toString(),
        });
        this.setState({
            tableParams: data2
        });
    }
    optionsChangeType(value) {
        let data = Object.assign({}, this.state.table, {
            current: 1,
        });
        this.setState({
            table: data
        });
        let data2 = Object.assign({}, this.state.tableParams, {
            rows: 10,
            page: 1,
            type: value,
        });
        this.setState({
            tableParams: data2
        });
    }
    componentDidMount() {
        beforeRouterEach(this)
        this.getDataList();
        this.getTag();
        this.getSpot();
        const tableBox = document.getElementById('table-box')
        tableBox.addEventListener('scroll', this.debounce(() => {
            if (tableBox.scrollTop>=this.state.nextScroll) {
                let obj=this.state.tableParams;
                obj.rows+=20;

                axios
                    .gets('/api/v1/route/find', this.state.tableParams)
                    .then((res) => {
                        if (res.status === 200) {
                            let data = Object.assign({}, this.state.table, {
                                data: res.data.records,
                                totalCount: res.data.total,
                            })

                            this.setState({
                                table: data,
                            })

                            let nextScroll=this.state.nextScroll*2;

                            this.setState({
                                nextScroll,
                                tableParams:obj
                            })

                            message.success('加载成功');
                        } else {
                            message.error(res.message)
                        }
                    })
            }
        },500))
    }

    debounce(fn,wait){
        var timer = null;
        return function(){
            if(timer !== null){
                clearTimeout(timer);
            }
            timer = setTimeout(fn,wait);
        }
    }

    getTag() {
        axios.gets('/api/v1/label/find', {
            rows: 100,
            page: 1,
            type: 'VISIT'
        })
            .then((res) => {
                if (res.status === 200) {
                    this.setState({
                        tagData: res.data.records
                    })
                } else {
                    message.error(res.message)
                }
            })
    }
    getSpot() {
        axios.gets('/api/v1/scenic/spot/find', {
            rows: 100,
            page: 1,
        })
            .then((res) => {
                if (res.status === 200) {
                    this.setState({
                        spotData: res.data.records
                    })
                } else {
                    message.error(res.message)
                }
            })
    }
    changeTripVisible(val) {
        if (val !== '') {
            let data = this.state.tripData;
            data.push(val)
            data.map((item, index) => {
                item.key = index;
                return item
            })
            data.map((item, index) => {
                this.state.spotData.map((items, indexs) => {
                    if (item.spotId === items.id) {
                        item.name = items.zhName
                    }
                    return items
                })
                return item
            })
            this.setState({
                tripData: data
            })
        }
    }
    seqDownTrip(val) {
        if (val.key < (this.state.tripData.length) - 1) {
            let data = deepCopy(this.state.tripData);
            let temp = deepCopy(data[val.key]);
            let key1 = val.key;
            let key2 = val.key + 1;
            data[val.key] = deepCopy(data[val.key + 1]);
            data[val.key + 1] = temp;
            data[val.key].key = key1;
            data[val.key + 1].key = key2;
            this.setState({
                tripData: data
            })
            // if (val.dayNum === data[val.key + 1].dayNum) {
            //     data[val.key] = deepCopy(data[val.key + 1]);
            //     data[val.key + 1] = temp;
            //     data[val.key].key = key1;
            //     data[val.key + 1].key = key2;
            //     this.setState({
            //         tripData: data
            //     })
            // } else {
            //     message.warning('只能调整同一天行程的顺序！')
            // }

        }
    }
    seqUpTrip(val) {
        if (val.key > 0) {
            let data = deepCopy(this.state.tripData);
            let temp = deepCopy(data[val.key]);
            let key1 = val.key;
            let key2 = val.key - 1;
            data[val.key] = deepCopy(data[val.key - 1]);
            data[val.key - 1] = temp;
            data[val.key].key = key1;
            data[val.key - 1].key = key2;
            this.setState({
                tripData: data
            })
            // if (val.dayNum === data[val.key - 1].dayNum) {
            //     data[val.key] = deepCopy(data[val.key - 1]);
            //     data[val.key - 1] = temp;
            //     data[val.key].key = key1;
            //     data[val.key - 1].key = key2;
            //     this.setState({
            //         tripData: data
            //     })
            // } else {
            //     message.warning('只能调整同一天行程的顺序！')
            // }
        }
    }
    changeModifyTripVisible(val) {
        if (val !== '') {
            let data = this.state.tripData;
            data.map((item, index) => {
                if (item.key === val.key) {
                    item.id = val.spotId;
                    item.time = val.time;
                    item.content = val.content;
                }
                return item
            })
            this.setState({
                tripData: data
            })
        }
    }
    changeDelTripVisible(val) {
        let data = this.state.tripData;
        data = data.filter(function (item, index) {
            return index !== val
        });
        this.setState({
            tripData: data
        })
    }
    changeDay(val) {
        let temp = Number(val);
        let data = []
        for (let i = 0; i < temp; i++) {
            let num = i + 1
            data.push({
                name: '第' + num + '天',
                value: num
            })
        }
        this.setState({
            dayData: data
        })
    }
    changeDayModify(val) {
        let temp = Number(val);
        let data = []
        for (let i = 0; i < temp; i++) {
            let num = i + 1
            data.push({
                name: '第' + num + '天',
                value: num
            })
        }
        this.setState({
            dayDataModify: data
        })
    }
    render() {
        return (
            <div className="regionManage">
                <Title titleMsg={this.state.titleMsg} />
                <SearchGroup
                    tagData={this.state.tagData}
                    typeData={this.state.typeData}
                    resetChange={this.resetChange.bind(this)}
                    resultDateSearch={this.resultDateSearch.bind(this)}
                    optionsChangeSex={this.optionsChangeSex.bind(this)}
                    optionsChangeType={this.optionsChangeType.bind(this)}
                    searchList={this.searchList.bind(this)}
                />
                <ButtonGroup
                    whichClick={this.whickBtnClicked.bind(this)}
                    btnGroup={this.state.btnGroup}
                />
                <TableGroup
                    selectedChange={this.selectedChange.bind(this)}
                    pageChange={this.tablePageChange.bind(this)}
                    sizeChange={this.tableSizeChange.bind(this)}
                    table={this.state.table} />
                <AddModal
                    tagData={this.state.tagData}
                    images={this.state.images}
                    getImage={this.getImage.bind(this)}
                    delImage={this.delImage.bind(this)}
                    changeVisible={this.changeAddModal.bind(this)}
                    visible={this.state.addModalVisible}
                    tripData={this.state.tripData}
                    spotData={this.state.spotData}
                    dayData={this.state.dayData}
                    changeDay={this.changeDay.bind(this)}
                    changeTripVisible={this.changeTripVisible.bind(this)}
                    changeModifyTripVisible={this.changeModifyTripVisible.bind(this)}
                    changeDelTripVisible={this.changeDelTripVisible.bind(this)}
                    seqDownTrip={this.seqDownTrip.bind(this)}
                    seqUpTrip={this.seqUpTrip.bind(this)}
                    typeData={this.state.typeData}
                />
                <ModifyModal
                    typeData={this.state.typeData}
                    detailData={this.state.detailData}
                    tagData={this.state.tagData}
                    images={this.state.images}
                    getImage={this.getImage.bind(this)}
                    delImage={this.delImage.bind(this)}
                    changeVisible={this.changeModifyModal.bind(this)}
                    visible={this.state.modifyModalVisible}
                    tripData={this.state.tripData}
                    spotData={this.state.spotData}
                    dayData={this.state.dayDataModify}
                    changeDay={this.changeDayModify.bind(this)}
                    changeTripVisible={this.changeTripVisible.bind(this)}
                    changeModifyTripVisible={this.changeModifyTripVisible.bind(this)}
                    changeDelTripVisible={this.changeDelTripVisible.bind(this)}
                    seqDownTrip={this.seqDownTrip.bind(this)}
                    seqUpTrip={this.seqUpTrip.bind(this)}
                />
            </div>
        );
    }
}

export default Index;
