import React, { Component } from 'react';
import {Row,Col,Icon, Modal} from 'antd';
import './mainHeader.css'
// import axios from '../axios';
const { confirm } = Modal;
class MainHeader extends Component {
    constructor(props,context){
        super(props,context);
        this.state={
            collapsed: false,
        }
    }
    toggleCollapsed(){
        this.setState({
          collapsed: !this.state.collapsed,
        },()=>{
            this.props.iscollapsed(this.state.collapsed);
        });
    }
    exit(){
        let _this=this;
        confirm({
            title: '退出',
            content: '确定退出该账号？',
            okText: '确定',
            okType: 'danger',
            cancelText: '取消',
            onOk() {
               
                 _this.props.isQuit(true)
                   
            },
            onCancel() {
              
            },
          });
    }
    render() {
        return (
            <div className="mainHeader" >
                <Row>
                    <Col xl={20} lg={20}>
                        <div className='menuBtn' onClick={this.toggleCollapsed.bind(this)}>
                            <Icon type={this.state.collapsed ? 'menu-unfold' : 'menu-fold'} />
                        </div>
                     </Col>
                    <Col xl={4} lg={4}>
                        <ul style={{float:'right'}}>
                            <li>{window.sessionStorage.getItem('userName')}</li>
                            <li onClick={this.exit.bind(this)}>退出</li>
                        </ul>
                    </Col>
                </Row>
    </div>
        );
    }
}

export default MainHeader;