import React, { Component } from 'react';
import axios from '../../axios'
import TableGroup from '../../base/table/index'
import Title from '../../base/title/index'
import AddModal from '../indexShowPageManage/addModal/index'
import ModifyModal from '../indexShowPageManage/modifyModal/index'
import { beforeRouterEach } from '../../common/index'
import { BaseUrl } from '../../config'
import VideoModal from './videoModal'
import { message, Modal, Divider, Row, Col, Button, Menu, Dropdown, Icon } from 'antd';
const { confirm } = Modal;

class Index extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            titleMsg: {
                titleName: '展示图设置',
            },
            table: {
                hasHeight: true,
                isPageShow: true,
                current: 1,
                totalCount: 0,
                columns: [
                    {
                        title: '排序',
                        dataIndex: 'column',
                        render: (text, record, index) => (
                            <span>
                                <span className='tableBtn' onClick={(event) => {
                                    this.seqUp(record, index)
                                    event.stopPropagation()
                                }}><Icon type="arrow-up" /></span><Divider type="vertical" />
                                <span className='tableBtn' onClick={(event) => {
                                    this.seqDown(record, index)
                                    event.stopPropagation()
                                }}><Icon type="arrow-down" /></span>
                            </span>
                        ),
                    },
                    {
                        title: '',
                        dataIndex: 'photo',
                        render: (text, record) => (
                            <span>
                                <span style={{ display: record.photo.indexOf('mp4') > -1 ? '' : 'none' }}>
                                    <video muted style={{ width: '150px', height: '115px' }} src={record.photo} autoPlay="autoPlay" controls="controls">

                                    </video>
                                </span>
                                <span style={{ display: record.photo.indexOf('mp4') > -1 ? 'none' : '' }}>
                                    <img style={{ width: '150px', height: '115px' }} src={record.photo} alt='' />
                                </span>
                            </span>

                        ),
                    },
                    {
                        title: '操作',
                        key: 'action',
                        render: (text, record) => (
                            <span>
                                <span>
                                    <span className='tableBtn' onClick={(event) => {
                                        this.modify(record)
                                        event.stopPropagation()
                                    }}>编辑</span><Divider type="vertical" />
                                    <span className='tableBtn' onClick={(event) => {
                                        this.del(record)
                                        event.stopPropagation()
                                    }}>删除</span>


                                </span>
                            </span>
                        ),
                    }
                ],
                data: [],
                isSelection: false
            },
            tableParams: {
                rows: 10,
                page: 1,
            },
            addModalVisible: false,
            modifyModalVisible: false,
            detailData: {

            },
            images: [],
            addModalVideoVisible: false,
            video: '',
            modifyVideo: false
        }
    }
    seqDown(record, index) {
        let length = index + 1;
        if (length < this.state.table.data.length) {
            let sort1 = this.state.table.data[length].sort;
            let sort2 = record.sort;
            record.sort = sort1;
            let downRow = this.state.table.data[length];
            downRow.sort = sort2;
            axios.posts('/api/v1/display/map/modify', record)
                .then((res) => {
                    if (res.status === 200) {
                        axios.posts('/api/v1/display/map/modify', downRow)
                            .then((res) => {
                                if (res.status === 200) {
                                    message.success('操作成功！')
                                    this.getDataList()
                                } else {
                                    message.error(res.message)
                                }
                            })
                    } else {
                        message.error(res.message)
                    }
                })
        }

    }
    seqUp(record, index) {
        if (index > 0) {
            let sort1 = this.state.table.data[index - 1].sort;
            let sort2 = record.sort;
            record.sort = sort1;
            let upRow = this.state.table.data[index - 1];
            upRow.sort = sort2;
            axios.posts('/api/v1/display/map/modify', record)
                .then((res) => {
                    if (res.status === 200) {
                        axios.posts('/api/v1/display/map/modify', upRow)
                            .then((res) => {
                                if (res.status === 200) {
                                    message.success('操作成功！')
                                    this.getDataList()
                                } else {
                                    message.error(res.message)
                                }
                            })
                    } else {
                        message.error(res.message)
                    }
                })
        }
    }
    handleMenuClick(e) {
        if (e.key === '1') {
            this.setState({
                addModalVisible: true
            })
        } else if (e.key === '2') {
            this.setState({
                addModalVideoVisible: true
            })
        }
    }

    modify(record) {
        if (record.photo.indexOf('mp4') > -1) {
            this.setState({
                detailData: record,
                video: record.photo
            }, () => {
                this.setState({
                    addModalVideoVisible: true,
                    modifyVideo: true
                })
            })
        } else {
            this.setState({
                detailData: record,
                images: record.photo.split(',')
            }, () => {
                this.setState({
                    modifyModalVisible: true
                })
            })
        }

    }
    del(record) {
        let _this = this;
        confirm({
            title: '是否删除当前图片?',
            content: '',
            onOk() {
                axios.posts('/api/v1/display/map/remove', { id: record.id })
                    .then((res) => {
                        if (res.status === 200) {
                            message.success('删除成功！')
                            _this.getDataList()
                        } else {
                            message.error(res.message)
                        }
                    })
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }
    getDataList() {
        axios.gets('/api/v1/display/map/find', this.state.tableParams)
            .then((res) => {
                if (res.status === 200) {
                    let data = Object.assign({}, this.state.table, {
                        data: res.data.records,
                        totalCount: res.data.total,
                    });
                    this.setState({
                        table: data
                    })
                } else {
                    message.error(res.message)
                }
            })


    }

    selectedChange(val) {
        console.log(val)
    }
    tableSizeChange(current, pageSize) {
        let data = Object.assign({}, this.state.table, {
            current: current,
        });
        this.setState({
            table: data
        });
        let data2 = Object.assign({}, this.state.tableParams, {
            rows: pageSize,
        });
        this.setState({
            tableParams: data2
        }, () => {
            this.getDataList();
        });

    }
    tablePageChange(page) {
        let data = Object.assign({}, this.state.table, {
            current: page,
        });
        this.setState({
            table: data
        });
        let data2 = Object.assign({}, this.state.tableParams, {
            page: page,
        });
        this.setState({
            tableParams: data2
        }, () => {
            this.getDataList();
        });
    }

    componentDidMount() {
        beforeRouterEach(this)
        this.getDataList();
    }
    changeAddModal(val, values) {
        this.setState({
            addModalVisible: val
        })
        if (values !== '') {
            values.photo = values.logo
            axios.posts('/api/v1/display/map/insert', values)
                .then((res) => {
                    if (res.status === 200) {
                        message.success('新增成功！')
                        this.getDataList();
                    } else {
                        message.error(res.message)
                    }
                })
        }
        this.setState({
            images: []
        })
    }
    changeModifyModal(val, values) {
        this.setState({
            modifyModalVisible: val
        })
        if (values !== '') {
            let data = {
                id: this.state.detailData.id,
                photo: values.logo
            }
            axios.posts('/api/v1/display/map/modify', data)
                .then((res) => {
                    if (res.status === 200) {
                        message.success('修改成功！')
                        this.getDataList();
                    } else {
                        message.error(res.message)
                    }
                })
        }
        this.setState({
            images: []
        })
    }

    getImage(val) {
        let data = this.state.images;
        data.push(BaseUrl.fetchUrl + val)
        this.setState({
            images: data
        })
    }
    delImage(val) {
        let data = this.state.images;
        data = data.filter(function (item, index) {
            return index !== val
        });

        this.setState({
            images: data
        })
    }
    changeVideoModal(val, values) {
        this.setState({
            addModalVideoVisible: val
        })
        if (values !== '') {

            if (this.state.modifyVideo) {
                let data = {
                    id: this.state.detailData.id,
                    photo: values.photo = values.video
                }
                axios.posts('/api/v1/display/map/modify', data)
                    .then((res) => {
                        if (res.status === 200) {
                            message.success('修改成功！')
                            this.getDataList();
                        } else {
                            message.error(res.message)
                        }
                    })
            } else {
                values.photo = values.video
                axios.posts('/api/v1/display/map/insert', values)
                    .then((res) => {
                        if (res.status === 200) {
                            message.success('新增成功！')
                            this.getDataList();
                        } else {
                            message.error(res.message)
                        }
                    })
            }

        }
        this.setState({
            video: ''
        })
    }
    getVideoUrl(val) {
        this.setState({
            video: BaseUrl.fetchUrl + val
        })
    }
    render() {
        const menu = (
            <Menu onClick={this.handleMenuClick.bind(this)}>
                <Menu.Item key="1">图片</Menu.Item>
                <Menu.Item key="2">视频</Menu.Item>
            </Menu>
        );
        return (
            <div className="superadminManage">
                <Title titleMsg={this.state.titleMsg} />
                <Row style={{ paddingLeft: '20px' }}>
                    <Col span={6}>
                        <Dropdown overlay={menu}>
                            <Button type='primary'>
                                新增 <Icon type="down" />
                            </Button>
                        </Dropdown>
                    </Col>
                </Row>
                <TableGroup
                    selectedChange={this.selectedChange.bind(this)}
                    pageChange={this.tablePageChange.bind(this)}
                    sizeChange={this.tableSizeChange.bind(this)}
                    table={this.state.table} />
                <AddModal
                    images={this.state.images}
                    getImage={this.getImage.bind(this)}
                    delImage={this.delImage.bind(this)}
                    changeVisible={this.changeAddModal.bind(this)}
                    visible={this.state.addModalVisible}
                />
                <ModifyModal
                    images={this.state.images}
                    getImage={this.getImage.bind(this)}
                    delImage={this.delImage.bind(this)}
                    changeVisible={this.changeModifyModal.bind(this)}
                    visible={this.state.modifyModalVisible}
                />
                <VideoModal
                    video={this.state.video}
                    visible={this.state.addModalVideoVisible}
                    changeVisible={this.changeVideoModal.bind(this)}
                    getVideoUrl={this.getVideoUrl.bind(this)}
                />
            </div>
        );
    }
}

export default Index;