import React from 'react'
import {Row, Col, Input, Button,Select} from 'antd'
const {Option} = Select

class SearchGroup extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            name:'',
            labelId:''
        }
    }

    onChangeResultDate(e) {
        this.setState({
            name:e.target.value
        })
        this.props.onChangeResultDate(e.target.value)
    }
    optionsChangeSex(value){
        this.setState({
            labelId:value
        })
        this.props.optionsChangeSex(value)
    }
 
    searchList() {
        this.props.searchList()
    }
    reset(){
        this.setState({
            name:'',
            labelId:''
        })
        this.props.resetChange()
    }

    render() {
        const data=this.props.data;
        return (
            <Row style={{padding: '10px 20px'}}>
                <Col offset={8} xl={5} lg={5} md={5}>
                    <label style={{marginRight: '5px'}}>指南分类：</label>
                    <Select
                        style={{width:'50%'}}
                        size={'small'}
                        value={this.state.labelId} onChange={this.optionsChangeSex.bind(this)}
                    >
                        {
                            data.map((item,index)=>{
                                return <Option key={index} value={item.id}>{item.name}</Option>
                            })
                        }
                    </Select>
                  
                </Col>
                <Col xl={6} lg={6} md={6}>
                    <label style={{marginRight: '5px'}}>指南名称：</label>
                    <Input style={{width:'50%'}} size={'small'} value={this.state.name}  onChange={this.onChangeResultDate.bind(this)}/>
                </Col>
                <Col xl={5} lg={5} md={5}>
                    <Button type="primary" onClick={this.searchList.bind(this)} style={{marginRight:'10px'}}>查询</Button>
                    <Button onClick={this.reset.bind(this)}>重置</Button>
                </Col>
            </Row>
        )
    }
}

export default SearchGroup