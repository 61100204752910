import React, { Component } from 'react';
import axios from '../../../axios'
import TableGroup from '../../../base/table/index'
import Title from '../../../base/title/index'
import ButtonGroup from '../../../base/buttonGroup/index'
import AddPermission from './addPermission/index'
import ModifyPermission from './modifyPermission/index'
import './index.css'
import { Divider, message, Modal } from 'antd';
const { confirm } = Modal;

class Index extends Component {
    constructor(props,context){
        super(props,context);
        this.state={
            btnGroup: [
                {
                    type:'back',
                    text:'返回'
                },{
                    type:'add',
                    text:'新增管理权限'
                },
            ],
            titleMsg: {
                titleName: '管理权限设置',
            },
            table: {
                hasHeight: true,
                isPageShow: true,
                current: 1,
                totalCount: 0,
                columns: [
                    {
                        title: '管理权限名称',
                        dataIndex: 'name',
                    },   
                    {
                        title: '操作',
                        key: 'action',
                        render: (text, record) => (
                            <span>
                                <span>
                                   <span className='tableBtn' onClick={(event) => {
                                            this.modify(record)
                                            event.stopPropagation()
                                        }}>编辑</span><Divider type="vertical"/>
                                    <span className='tableBtn' onClick={(event) => {
                                            this.del(record)
                                            event.stopPropagation()
                                        }}>删除</span>
                                    
                                   
                                </span>
                        </span>
                        ),
                    }
                ],
                data: [],
                isSelection: false
            },
            tableParams:{
                rows:10,
                page:1,
            },
            addModalVisible:false,
            modifyModalVisible:false,
            modifyData:{}
        }
    }
    whickBtnClicked(val){
        if(val==='add'){
            this.setState({
                addModalVisible:true
            })
        }else if(val==='back'){
            this.props.history.push('/index/superadminManage');
        }
    } 
    modify(record){
        axios.gets('/api/v1/user/menu/detail',{id:record.id})
            .then((res)=>{
                if(res.status===200){
                    if(res.data.content!==null){
                        res.data.content=res.data.content.split(',')
                    }
                    this.setState({
                        modifyData:res.data
                    },()=>{
                        this.setState({
                            modifyModalVisible:true
                        })
                    })
                   
                }else{
                    message.error(res.message)
                }
            })
       
    }
    del(record){
        let _this=this;
        confirm({
            title: '是否删除当前数据?',
            content: '',
            onOk() {
              axios.posts('/api/v1/user/menu/remove',{id:record.id})
                .then((res)=>{
                    if(res.status===200){
                        message.success('删除成功！')
                        _this.getDataList()
                    }else{
                        message.error(res.message)
                    }
                })
            },
            onCancel() {
              console.log('Cancel');
            },
        });
    }
    changeAddModal(val,values){
        this.setState({
            addModalVisible:val
        })
        if(values!==''){
            let data={
                name:values.name,
                content:values.content.join(',')
            }
            axios.posts('/api/v1/user/menu/insert',data)
                .then((res)=>{
                    if(res.status===200){
                        message.success('新增成功！')
                        this.getDataList();
                    }else{
                        message.error(res.message)
                    }
                })
        }
        
    }
    changeModifyModal(val,values){
        this.setState({
            modifyModalVisible:val
        })
        if(values==='submit'){
            let data=this.state.modifyData
            data.content=data.content.join(',')
            axios.posts('/api/v1/user/menu/modify',data)
                .then((res)=>{
                    if(res.status===200){
                        message.success('修改成功！')
                        this.getDataList();
                    }else{
                        message.error(res.message)
                    }
                })
        }
    }
    getDataList(){
        axios.gets('/api/v1/user/menu/find',this.state.tableParams)
            .then((res)=>{
                if(res.status===200){
                    let data = Object.assign({}, this.state.table, {
                        data: res.data,
                        totalCount: res.total
                    });
                    this.setState({
                        table: data
                    })
                }else{
                    message.error(res.message)
                }
            })
        
    }
    selectedChange(val){
        console.log(val)
    }
    tableSizeChange(current, pageSize) {
        let data = Object.assign({}, this.state.table, {
            current: current,
        });
        this.setState({
            table: data
        });
        let data2 = Object.assign({}, this.state.tableParams, {
            rows: pageSize,
        });
        this.setState({
            tableParams: data2
        }, () => {
            this.getDataList();
        });

    }
    tablePageChange(page) {
        let data = Object.assign({}, this.state.table, {
            current: page,
        });
        this.setState({
            table: data
        });
        let data2 = Object.assign({}, this.state.tableParams, {
            page: page ,
        });
        this.setState({
            tableParams: data2
        }, () => {
            this.getDataList();
        });
    }
   
    componentDidMount(){
        this.getDataList();
    }
    changePermissionArrVisible(val){
        let data = Object.assign({}, this.state.modifyData, {
            content: val,
        });
        this.setState({
            modifyData: data
        });
    }
    changePermissionNameVisible(val){
        console.log(val)
    }
    render() {
        return (
            <div className="setPermission">
                <Title titleMsg={this.state.titleMsg}/> 
               <ButtonGroup
                    whichClick={this.whickBtnClicked.bind(this)}
                    btnGroup={this.state.btnGroup}
               />
               <TableGroup
                    selectedChange={this.selectedChange.bind(this)}
                    pageChange={this.tablePageChange.bind(this)}
                    sizeChange={this.tableSizeChange.bind(this)}
                    table={this.state.table}/>
               <AddPermission
                    changeVisible={this.changeAddModal.bind(this)}
                    visible={this.state.addModalVisible}
               /> 
               <ModifyPermission
                    changeVisible={this.changeModifyModal.bind(this)}
                    visible={this.state.modifyModalVisible}
                    changePermissionArrVisible={this.changePermissionArrVisible.bind(this)}
                    changePermissionNameVisible={this.changePermissionNameVisible.bind(this)}
                    data={this.state.modifyData}
               />
            </div>
        );
    }
}

export default Index;