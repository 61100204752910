import React, { Component } from 'react';
import axios from '../../axios'
import { beforeRouterEach } from '../../common/index'
import { BaseUrl } from '../../config'
import TableGroup from '../../base/table1/index'
import Title from '../../base/title/index'
import ButtonGroup from '../../base/buttonGroup/index'
import SearchGroup from './searchGroup'
import AddModal from './addModal/index'
import ModifyModal from './modifyModal/index'
import './index.css'
import { Divider, message, Modal, Icon, Button } from 'antd';

const { confirm } = Modal;

class Index extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            btnGroup: [
                {
                    type: 'add',
                    text: '新增'
                },
                {
                    type: 'del',
                    text: '删除'
                }
            ],
            titleMsg: {
                titleName: '旅行指南管理',
            },
            table: {
                hasHeight: true,
                isPageShow: true,
                current: 1,
                totalCount: 0,
                columns: [
                    {
                        title: '排序',
                        dataIndex: 'column',
                        render: (text, record, index) => (
                            <span>
                                <span className='tableBtn' onClick={(event) => {
                                    this.toTop(record)
                                    event.stopPropagation()
                                }}><Button type="link">置顶</Button></span><Divider type="vertical" />
                                <span className='tableBtn' onClick={(event) => {
                                    this.seqUp(record, index)
                                    event.stopPropagation()
                                }}><Icon type="arrow-up" /></span><Divider type="vertical" />
                                <span className='tableBtn' onClick={(event) => {
                                    this.seqDown(record, index)
                                    event.stopPropagation()
                                }}><Icon type="arrow-down" /></span>
                            </span>
                        ),
                    },
                    {
                        title: '指南名称',
                        dataIndex: 'name',
                    },
                    {
                        title: '指南分类',
                        dataIndex: 'labelName',
                    },
                    {
                        title: '指南推荐',
                        dataIndex: 'recommend',
                        render: (text, record) => (
                            <span>
                                {record.recommend === 1 ? '是' : '否'}
                            </span>
                        ),
                    },
                    {
                        title: '操作',
                        key: 'action',
                        render: (text, record) => (
                            <span>
                                <span>
                                    <span className='tableBtn' onClick={(event) => {
                                        this.modify(record)
                                        event.stopPropagation()
                                    }}>编辑</span><Divider type="vertical" />
                                    <span className='tableBtn' onClick={(event) => {
                                        this.del(record)
                                        event.stopPropagation()
                                    }}>删除</span>


                                </span>
                            </span>
                        ),
                    }
                ],
                data: [],
                isSelection: false
            },
            tableParams: {
                rows: 10,
                page: 1,
                labelId: '',
                name: ''
            },
            addModalVisible: false,
            modifyModalVisible: false,

            detailData: {
                photo: []
            },
            selected: [],
            tag: [],
            images: [],
            nextScroll: 39
        }
    }
    toTop(record) {
        axios.gets('/api/v1/guide/sticky', { id: record.id })
            .then((res) => {
                if (res.status === 200) {
                    message.success('置顶成功！')
                    this.getDataList()
                } else {
                    message.error(res.message)
                }
            })
    }
    seqDown(record, index) {
        let length = index + 1;
        if (length < this.state.table.data.length) {
            let sort1 = this.state.table.data[length].sort;
            let sort2 = record.sort;
            record.sort = sort1;
            let downRow = this.state.table.data[length];
            downRow.sort = sort2;
            axios.posts('/api/v1/guide/modify', record)
                .then((res) => {
                    if (res.status === 200) {
                        axios.posts('/api/v1/guide/modify', downRow)
                            .then((res) => {
                                if (res.status === 200) {
                                    message.success('操作成功！')
                                    this.getDataList()
                                } else {
                                    message.error(res.message)
                                }
                            })
                    } else {
                        message.error(res.message)
                    }
                })
        }

    }
    seqUp(record, index) {
        if (index > 0) {
            let sort1 = this.state.table.data[index - 1].sort;
            let sort2 = record.sort;
            record.sort = sort1;
            let upRow = this.state.table.data[index - 1];
            upRow.sort = sort2;
            axios.posts('/api/v1/guide/modify', record)
                .then((res) => {
                    if (res.status === 200) {
                        axios.posts('/api/v1/guide/modify', upRow)
                            .then((res) => {
                                if (res.status === 200) {
                                    message.success('操作成功！')
                                    this.getDataList()
                                } else {
                                    message.error(res.message)
                                }
                            })
                    } else {
                        message.error(res.message)
                    }
                })
        }
    }
    whickBtnClicked(val) {
        if (val === 'add') {
            this.setState({
                addModalVisible: true
            })
        } else if (val === 'del') {
            let _this = this;
            let ids = _this.state.selected;
            confirm({
                title: '是否删除当前选中指南?',
                content: '',
                onOk() {
                    axios.posts('/api/v1/guide/remove', { ids: ids })
                        .then((res) => {
                            if (res.status === 200) {
                                message.success('删除成功！')
                                _this.getDataList()
                                _this.setState({
                                    selected: []
                                })
                            } else {
                                message.error(res.message)
                            }
                        })
                },
                onCancel() {
                    console.log('Cancel');
                },
            });
        }
    }
    modify(record) {
        record.recommend = record.recommend.toString()
        if (typeof record.photo === 'string') {
            record.photo = record.photo.split(',')
        }
        this.setState({
            detailData: record
        }, () => {
            this.setState({
                modifyModalVisible: true
            })
        })
    }
    del(record) {
        let _this = this;
        confirm({
            title: '是否删除当前指南?',
            content: '',
            onOk() {
                axios.posts('/api/v1/guide/remove', { ids: [record.id] })
                    .then((res) => {
                        if (res.status === 200) {
                            message.success('删除成功！')
                            _this.getDataList()
                        } else {
                            message.error(res.message)
                        }
                    })
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }
    getDataList() {
        axios.gets('/api/v1/guide/find', this.state.tableParams)
            .then((res) => {
                if (res.status === 200) {
                    let data = Object.assign({}, this.state.table, {
                        data: res.data.records,
                        totalCount: res.data.total,
                    });
                    this.setState({
                        table: data
                    })
                } else {
                    message.error(res.message)
                }
            })
    }

    selectedChange(val) {
        this.setState({
            selected: val
        })
    }
    tableSizeChange(current, pageSize) {
        let data = Object.assign({}, this.state.table, {
            current: current,
        });
        this.setState({
            table: data
        });
        let data2 = Object.assign({}, this.state.tableParams, {
            rows: pageSize,
        });
        this.setState({
            tableParams: data2
        }, () => {
            this.getDataList();
        });

    }
    tablePageChange(page) {
        let data = Object.assign({}, this.state.table, {
            current: page,
        });
        this.setState({
            table: data
        });
        let data2 = Object.assign({}, this.state.tableParams, {
            page: page,
        });
        this.setState({
            tableParams: data2
        }, () => {
            this.getDataList();
        });
    }

    componentDidMount() {
        beforeRouterEach(this)
        this.getDataList();
        this.getTagType();
        const tableBox = document.getElementById('table-box')
        tableBox.addEventListener('scroll', this.debounce(() => {
            if (tableBox.scrollTop>=this.state.nextScroll) {
                let obj=this.state.tableParams;
                obj.rows+=20;

                axios
                    .gets('/api/v1/guide/find', this.state.tableParams)
                    .then((res) => {
                        if (res.status === 200) {
                            let data = Object.assign({}, this.state.table, {
                                data: res.data.records,
                                totalCount: res.data.total,
                            })

                            this.setState({
                                table: data,
                            })

                            let nextScroll=this.state.nextScroll*2;

                            this.setState({
                                nextScroll,
                                tableParams:obj
                            })

                            message.success('加载成功');
                        } else {
                            message.error(res.message)
                        }
                    })
            }
        },500))
    }

    debounce(fn,wait){
        var timer = null;
        return function(){
            if(timer !== null){
                clearTimeout(timer);
            }
            timer = setTimeout(fn,wait);
        }
    }

    changeAddModal(val, values) {
        this.setState({
            addModalVisible: val,
            images: []
        })
        if (values !== '') {
            values.recommend = parseInt(values.recommend)
            values.labelId = parseInt(values.labelId)
            axios.posts('/api/v1/guide/insert', values)
                .then((res) => {
                    if (res.status === 200) {
                        message.success('新增成功！')
                        this.getDataList();
                    } else {
                        message.error(res.message)
                    }
                })
        }
    }
    changeModifyModal(val, values) {
        this.setState({
            modifyModalVisible: val,
            images: []
        })
        if (values !== '') {
            values.id = this.state.detailData.id
            values.recommend = parseInt(values.recommend)
            values.labelId = parseInt(values.labelId)
            axios.posts('/api/v1/guide/modify', values)
                .then((res) => {
                    if (res.status === 200) {
                        message.success('修改成功！')
                        this.getDataList();
                    } else {
                        message.error(res.message)
                    }
                })
        }
    }

    resetChange() {
        let data = Object.assign({}, this.state.table, {
            current: 1,
        });
        this.setState({
            table: data
        });
        let data2 = Object.assign({}, this.state.tableParams, {
            rows: 10,
            page: 1,
            name: '',
            labelId: ''
        });
        this.setState({
            tableParams: data2
        }, () => {
            this.getDataList();
        });
    }
    searchList() {
        this.getDataList();
    }
    onChangeResultDate(value) {
        let data = Object.assign({}, this.state.table, {
            current: 1,
        });
        this.setState({
            table: data
        });
        let data2 = Object.assign({}, this.state.tableParams, {
            rows: 10,
            page: 1,
            name: value,
        });
        this.setState({
            tableParams: data2
        });
    }
    optionsChangeSex(value) {
        let data = Object.assign({}, this.state.table, {
            current: 1,
        });
        this.setState({
            table: data
        });
        let data2 = Object.assign({}, this.state.tableParams, {
            rows: 10,
            page: 1,
            labelId: value,
        });
        this.setState({
            tableParams: data2
        });
    }
    getTagType() {
        axios.gets('/api/v1/label/find', { page: 1, rows: 100, type: 'GUIDE' })
            .then((res) => {
                if (res.status === 200) {
                    this.setState({
                        tag: res.data.records
                    })
                } else {
                    message.error(res.message)
                }
            })
    }
    getImage(val) {
        let data = this.state.images;
        data.push(BaseUrl.fetchUrl + val)
        this.setState({
            images: data
        })
    }
    delImage(val) {
        let data = this.state.images;
        data = data.filter(function (item, index) {
            return index !== val
        });

        this.setState({
            images: data
        })
    }
    getImageModify(val) {
        let data = this.state.detailData.photo;
        data.push(BaseUrl.fetchUrl + val);
        let image = Object.assign({}, this.state.detailData, {
            photo: data,
        });
        this.setState({
            detailData: image
        });
    }
    delImageModify(val) {
        let data = this.state.images;
        data = data.filter(function (item, index) {
            return index !== val
        });
        let image = Object.assign({}, this.state.detailData, {
            photo: data,
        });
        this.setState({
            detailData: image
        });
    }
    callback(key) {
        this.setState({
            currentGuideType: key
        })

    }
    render() {
        return (
            <div className="guideManage">
                <Title titleMsg={this.state.titleMsg} />
                <SearchGroup
                    data={this.state.tag}
                    resetChange={this.resetChange.bind(this)}
                    onChangeResultDate={this.onChangeResultDate.bind(this)}
                    optionsChangeSex={this.optionsChangeSex.bind(this)}
                    searchList={this.searchList.bind(this)}
                />
                <ButtonGroup
                    whichClick={this.whickBtnClicked.bind(this)}
                    btnGroup={this.state.btnGroup}
                />
                <TableGroup
                    selectedChange={this.selectedChange.bind(this)}
                    pageChange={this.tablePageChange.bind(this)}
                    sizeChange={this.tableSizeChange.bind(this)}
                    table={this.state.table} />
                <AddModal
                    changeVisible={this.changeAddModal.bind(this)}
                    getImage={this.getImage.bind(this)}
                    delImage={this.delImage.bind(this)}
                    typedata={this.state.tag}
                    images={this.state.images}
                    visible={this.state.addModalVisible}
                />
                <ModifyModal
                    detailData={this.state.detailData}
                    typedata={this.state.tag}
                    getImage={this.getImageModify.bind(this)}
                    delImage={this.delImageModify.bind(this)}
                    changeVisible={this.changeModifyModal.bind(this)}
                    visible={this.state.modifyModalVisible}
                />

            </div>
        );
    }
}

export default Index;
