import React, { Component } from 'react';
import axios from '../../axios'
import Title from '../../base/title/index'
import SearchGroup from './searchGroup'
import SearchGroupList from './searchGroupList'
import TableGroup from '../../base/table/index'
import ButtonGroup from '../../base/buttonGroup/index'
import { beforeRouterEach } from '../../common/index'
import { Row, Col, Modal, Tabs } from 'antd';
import { BaseUrl } from '../../config'
//导入折线图
import 'echarts/lib/chart/line';  //折线图是line,饼图改为pie,柱形图改为bar
import 'echarts/lib/chart/bar';
import 'echarts/lib/component/tooltip';
import 'echarts/lib/component/title';
import 'echarts/lib/component/legend';
import 'echarts/lib/component/markPoint';
import ReactEcharts from 'echarts-for-react';
const { TabPane } = Tabs;
const { confirm } = Modal;
class Index extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            titleMsg: {
                titleName: '数据分析',
            },
            btnGroup: [
                {
                    type: 'export',
                    text: '导出'
                }
            ],
            tableParams: {
                scenicSpotType: 'SCENIC_SPOT',
                startTime: '2018-01-01 00:00:00',
                endTime: '',
                asc: false
            },
            option: {
                toolbox: {
                    show: true,
                    x: 150,
                    y: 0,
                    feature: {
                        mark: { show: true },
                        magicType: { show: true, type: ['line', 'bar'] },
                        restore: { show: true },
                    }
                },
                color: ['#1890ff'],
                title: {
                    text: '',
                    x: 'center'
                },
                tooltip: {
                    trigger: 'axis',
                },
                xAxis: {
                    type: 'category',
                    axisTick: { show: false },
                    data: []
                },
                yAxis: {
                    type: 'value'
                },
                legend: {
                    data: ['访问量'],
                    icon: "rect",
                    x: 'center', // 'center' | 'left' | {number},
                    y: 'bottom' // 'center' | 'bottom' | {number}
                },
                series: [

                ]
            },
            contentHeight: '',
            tableParamsList: {
                scenicSpotType: 'SCENIC_SPOT',
                scenicSpotZhName: '',
                asc: false
            },
            table: {
                hasHeight: true,
                isPageShow: false,
                current: 1,
                totalCount: 0,
                columns: [
                    {
                        title: '名称(主)',
                        dataIndex: 'zhName',
                    }, {
                        title: '名称(副)',
                        dataIndex: 'enName',
                    },{
                        title: '访问次数',
                        dataIndex: 'visit' 
                    }, 
                    {
                        title: '访问人数',
                        dataIndex: 'persons' 
                    },
                    {
                        title: '分享次数',
                        dataIndex: 'share' 
                    }, 
                    {
                        title: '收藏次数',
                        dataIndex: 'collect' 
                    }, 
                ],
                data: [],
                isSelection: false
            },
        }
    }
    componentDidMount() {
        beforeRouterEach(this)
        let _height = window.innerHeight;
        this.setState({
            contentHeight: _height - 80
        });
        this.getCurrentTime()
        this.getDataList2()
    }
    dateFormat(DateIn) {
        var Year = 0;
        var Month = 0;
        var Day = 0;
        var CurrentDate = "";
        //初始化时间
        Year = DateIn.getYear();
        Month = DateIn.getMonth() + 1;
        Day = DateIn.getDate();
        Year = (Year < 1900 ? (1900 + Year) : Year);
        CurrentDate = Year + "-";
        if (Month >= 10) {
            CurrentDate = CurrentDate + Month + "-";
        }
        else {
            CurrentDate = CurrentDate + "0" + Month + "-";
        }
        if (Day >= 10) {
            CurrentDate = CurrentDate + Day;
        }
        else {
            CurrentDate = CurrentDate + "0" + Day;
        }
        return CurrentDate;
    }
    getCurrentTime() {
        const date = new Date();
        let year = date.getFullYear();
        let month = date.getMonth() + 1;
        let day = date.getDate();
        month = month > 9 ? month : '0' + month;;
        day = day > 9 ? day : '0' + day;
        var dateStart = new Date((new Date().getTime() - 1000 * 60 * 60 * 24 * 29));  //29     
        dateStart = this.dateFormat(dateStart);
        let data2 = Object.assign({}, this.state.tableParams, {
            startTime: `${dateStart} 00:00:00`,
            endTime: `${year}-${month}-${day} 23:59:59`
        });
        this.setState({
            tableParams: data2
        }, () => {
            this.getDataList()
        });
    }
    whickBtnClicked(val) {
        if (val === 'export') {
            let _this = this;
            confirm({
                title: '是否导出当前数据?',
                content: '',
                onOk() {
                    window.location.href = BaseUrl.fetchUrl + '/api/v1/visit/record/excel-export?scenicSpotType=' + _this.state.tableParamsList.scenicSpotType + '&startTime=' + _this.state.tableParams.startTime + '&endTime=' + _this.state.tableParams.endTime+'&scenicSpotZhName='+_this.state.tableParamsList.scenicSpotZhName+'&asc='+_this.state.tableParamsList.asc
                },
                onCancel() {
                    console.log('Cancel');
                },
            });
        }
    }
    getDataList() {
        axios.gets('/api/v1/visit/record/statistics-top', this.state.tableParams)
            .then(res => {
                let xData = []
                let series = [
                    {
                        name: '访问量',
                        type: 'bar',
                        data: [],
                        itemStyle: {
                            normal: {
                                color: function (params) {
                                    return '#1890ff'
                                }
                            }
                        }

                    }
                ]
                for (let i in res.data) {
                    xData.push(res.data[i].scenicSpotName)
                    series[0].data.push(res.data[i].num)
                }
                let chartData = Object.assign({}, this.state.option, {
                    xAxis: {
                        type: 'category',
                        axisTick: { show: false },
                        data: xData
                    },
                    series: series
                });
                this.setState({
                    option: chartData
                });
            })
    }
    getDataList2() {
        axios.gets('/api/v1/visit/record/statistics-form', this.state.tableParamsList)
            .then(res => {
                if(res.status===200){
                    let data = Object.assign({}, this.state.table, {
                        data: res.data
                    });
                    this.setState({
                        table: data
                    })
                }
            })
    }
    searchList() {
        this.getDataList();
    }
    searchList2() {
        this.getDataList2()
    }
    dateChange(value) {
        let data2 = {}
        if (value[0] !== '' && value[1] !== '') {
            data2 = Object.assign({}, this.state.tableParams, {
                startTime: value[0],
                endTime: value[1]
            });
        } else {
            const date = new Date();
            let year = date.getFullYear();
            let month = date.getMonth() + 1;
            let day = date.getDate();
            month = month > 9 ? month : '0' + month;;
            day = day > 9 ? day : '0' + day;
            var dateStart = new Date((new Date().getTime() - 1000 * 60 * 60 * 24 * 29));  //29     
            dateStart = this.dateFormat(dateStart);
            data2 = Object.assign({}, this.state.tableParams, {
                startTime: `${dateStart} 00:00:00`,
                endTime: `${year}-${month}-${day} 23:59:59`
            });
        }

        this.setState({
            tableParams: data2
        });
    }
    resetChange() {
        const date = new Date();
        let year = date.getFullYear();
        let month = date.getMonth() + 1;
        let day = date.getDate();
        month = month > 9 ? month : '0' + month;;
        day = day > 9 ? day : '0' + day;
        var dateStart = new Date((new Date().getTime() - 1000 * 60 * 60 * 24 * 29));  //29     
        dateStart = this.dateFormat(dateStart);
        let data2 = Object.assign({}, this.state.tableParams, {
            scenicSpotType: 'SCENIC_SPOT',
            startTime: `${dateStart} 00:00:00`,
            endTime: `${year}-${month}-${day} 23:59:59`,
            asc: false
        });
        this.setState({
            tableParams: data2
        }, () => {
            this.getDataList();
        });
    }
    resetChangeList() {
        let data2 = Object.assign({}, this.state.tableParamsList, {
            scenicSpotZhName: '',
            asc: false
        });
        this.setState({
            tableParamsList: data2
        }, () => {
            this.getDataList2();
        });
    }
    callback(key) {
        let data2 = Object.assign({}, this.state.tableParams, {
            scenicSpotType: key,
            asc: false
        });
        let data3 = Object.assign({}, this.state.tableParamsList, {
            scenicSpotType: key,
            asc: false
        });
        this.setState({
            tableParams: data2,
            tableParamsList: data3
        }, () => {
            this.getDataList();
            this.getDataList2();
        });
    }
    optionsChangeSex(val) {
        let data2 = Object.assign({}, this.state.tableParamsList, {
            asc: val,
        });
        this.setState({
            tableParamsList: data2
        });
    }
    onChangeResultName(val) {
        let data2 = Object.assign({}, this.state.tableParamsList, {
            scenicSpotZhName: val,
        });
        this.setState({
            tableParamsList: data2
        });
    }
    render() {
        return (
            <div className="regionManage" style={{ height: this.state.contentHeight, overflowY: 'scroll' }}>
                <Title titleMsg={this.state.titleMsg} />
                <Tabs defaultActiveKey="SCENIC_SPOT" style={{ margin: '0 20px' }} onChange={this.callback.bind(this)} type="card">
                    <TabPane tab="景点" key="SCENIC_SPOT">

                    </TabPane>
                    <TabPane tab="美食" key="FOOD">

                    </TabPane>
                    <TabPane tab="酒店" key="HOTEL">

                    </TabPane>
                    <TabPane tab="购物" key="SHOPPING">

                    </TabPane>

                    <TabPane tab="交通" key="TRAFFIC">

                    </TabPane>
                    <TabPane tab="玩乐" key="LEISURE">

                    </TabPane>
                </Tabs>
                <SearchGroup
                    resetChange={this.resetChange.bind(this)}
                    dateChange={this.dateChange.bind(this)}
                    searchList={this.searchList.bind(this)}
                />

                <Row>
                    <Col offset={1} span={22}>
                        <ReactEcharts option={this.state.option} theme="Imooc" style={{ height: 400 }} />
                    </Col>
                </Row>
                <SearchGroupList
                    optionsChangeSex={this.optionsChangeSex.bind(this)}
                    onChangeResultName={this.onChangeResultName.bind(this)}
                    resetChange={this.resetChangeList.bind(this)}
                    searchList={this.searchList2.bind(this)}
                />
                <ButtonGroup
                    whichClick={this.whickBtnClicked.bind(this)}
                    btnGroup={this.state.btnGroup}
                />
                 <TableGroup
                    table={this.state.table}/>
            </div>
        );
    }
}

export default Index;