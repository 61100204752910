import React from 'react'
import {Row, Col,Button,Modal,Input,Radio } from 'antd'

class CreateModal extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            accountName:'',
            permissionArray:''
        }
    }
    createAccount= (e) => {
        e.preventDefault();
        this.props.changeVisible(false,{
            accountName:this.state.accountName,
            permissionArray:this.state.permissionArray
        });
        this.setState({
            accountName:'',
            permissionArray:''
        })
    }
    cancelCreateAccount(){
        this.props.changeVisible(false,'');
        this.setState({
            accountName:'',
            permissionArray:''
        })
    }
    
    onChangeResultDate(e) {
        this.setState({
            accountName:e.target.value
        })
    }
    changeEmployeePermission = e => {
        this.setState({
            permissionArray:e.target.value
        });
    };
    render() {
        const data=this.props.permissionArr
        return (
            <Modal
                    maskClosable={false}
                    destroyOnClose={true}
                    width={600}
                    visible={this.props.visible}
                    title={"新增管理员"}
                    onOk={this.createAccount.bind(this)}
                    onCancel={this.cancelCreateAccount.bind(this)}
                    footer={[
                        <Button key="back" onClick={this.cancelCreateAccount.bind(this)}>
                        取消
                        </Button>,
                        <Button key="submit" type="primary" onClick={this.createAccount.bind(this)}>
                        确定
                        </Button>,
                    ]}>
                    <Row>
                        <Col span={24}>
                            <label>账号：</label>
                            <Input value={this.state.accountName}  onChange={this.onChangeResultDate.bind(this)} style={{width:'70%'}} size={'small'}/>
                        </Col>
                        <Col span={24} style={{marginTop:'20px'}}>
                            <label>权限管理：</label>
                            <Radio.Group
                                options={data}
                                value={this.state.permissionArray}
                                onChange={this.changeEmployeePermission.bind(this)}
                            />
                        </Col>
                        <Col span={24} style={{marginTop:'20px'}}>
                            <label>初始密码：123456</label>
                        </Col>
                    </Row>
                </Modal>    
        )
    }
}

export default CreateModal;