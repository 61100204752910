import React from 'react'
import {Row, Col,Button,Modal,Form  } from 'antd'
import UploadBox from '../../../base/upload/index'

class CreateModal extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            
        }
    }

    createAccount= (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.props.changeVisible(false,values);
                this.props.form.resetFields();
            }
        });
    }
    cancelCreateAccount(){
        this.props.form.resetFields();
        this.props.changeVisible(false,'');
    }
    getImage(val){
        this.props.getImage(val)
    }
    delImage(value){
        this.props.delImage(value)
    }
    
    render() {
        const {getFieldDecorator} = this.props.form;
        const images=this.props.images
        const name=this.props.name
        return (
            <Modal
                    maskClosable={false}
                    destroyOnClose={true}
                    visible={this.props.visible}
                    title={"编辑"}
                    onOk={this.createAccount.bind(this)}
                    onCancel={this.cancelCreateAccount.bind(this)}
                    footer={[
                        <Button key="back" onClick={this.cancelCreateAccount.bind(this)}>
                        取消
                        </Button>,
                        <Button key="submit" type="primary" onClick={this.createAccount.bind(this)}>
                        确定
                        </Button>,
                    ]}>
                    <Form>
                        <Row>
                            
                            <Col span={24}>
                                <Form.Item
                                    label=""
                                    labelCol={{span: 4}} wrapperCol={{span: 20}}
                                >
                                    {getFieldDecorator('logo', {
                                        initialValue: images.join(','),
                                        rules: [{
                                            required: true, message: '请上传图片!',
                                        }],
                                    })(
                                        <span style={{display:'none'}}></span>
                                    )}
                                   <Row>
                                        <Col span={24}>
                                        {
                                            images.map((item,index)=>{
                                                return  <div key={index} style={{height: (name==='welcome') ? "126px" : "95px",width: (name==='welcome') ? "95px" : "126px",display:'inline-block',marginLeft:'10px',backgroundSize:'100% 100%',backgroundImage:'url('+ item +')'}}>
                                                <div style={{cursor:'pointer',display:'inline',float:'right',lineHeight:'20px'}} onClick={this.delImage.bind(this,index)}>
                                                    <img src={[require("../../../assets/image/goodsFiles/del.png")]} alt="" />
                                                </div>
                                            
                                            </div>
                                            })
                                        }
                                        {
                                            images.length>0?'':<UploadBox getImage={this.getImage.bind(this)}/>
                                        }
                                        
                                        </Col>
                                    </Row>
                                   {
                                        name==='welcome'?<Row>
                                        <Col span={24}>
                                        <p>1. 图片宽高比要求3:4</p>
                                        <p>2. jpg格式,大小300k-1M(在保证清晰度的情况下尽可能压缩)</p>
                                        </Col>
                                    </Row>:<Row>
                                        <Col span={24}>
                                        <p>1. 图片宽高比要求16:9</p>
                                        <p>2. jpg格式,大小300k-1M(在保证清晰度的情况下尽可能压缩)</p>
                                        </Col>
                                    </Row>
                                    }
                                </Form.Item>
                            </Col> 

                        </Row>
                    </Form>
                    
                </Modal>    
        )
    }
}

export default Form.create({name: 'modifyIndexPage'})(CreateModal); 