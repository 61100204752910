import React from 'react'
import {Row, Col, Input, Button,Form, message} from 'antd'
import {beforeRouterEach} from '../../common/index'
import Title from '../../base/title/index'
import axios from '../../axios/index'
import './index.css'
class CreateModal extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            titleMsg: {
                titleName: '系统安全',
            },
            id:'',
            userName:""
        }
    }

    createAccount= (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                console.log(values)
                let data={
                    id:this.state.id,
                    passWord:values.passWord,
                    newPassWord:values.newPassWord
                }
                axios.posts('/api/v1/user/update/pass',data)
                    .then((res)=>{
                        if(res.status===200){
                            message.success('修改成功！');
                            this.props.form.resetFields();
                        }else{
                            message.error(res.message)
                        }
                    })
            }
        });
    }
    validFunctionOld=(rule, value, callback) => {
        if(value!==this.state.passWord){
            callback('密码错误'); // 校验未通过
        }
         
        callback(); // 校验通过
    }

    validFunction = (rule, value, callback) => {
        if(value!==this.props.form.getFieldValue('newPassWord')){
            callback('密码不一致'); // 校验未通过
        }
         
        callback(); // 校验通过
    }
      
    
    componentDidMount(){
        beforeRouterEach(this)
        let id=window.sessionStorage.getItem('userId');
        let userName=window.sessionStorage.getItem('userName');
        let pass=window.sessionStorage.getItem('password');
        this.setState({
            id:id,
            userName:userName,
            passWord:pass
        })
    }
  
    render() {
        const {getFieldDecorator} = this.props.form;
        return (
                <div className='systemSafe'>
                    <Title titleMsg={this.state.titleMsg}/> 
                    <Form>
                        <Row>
                            <Col span={24}>
                                <Form.Item
                                    label="账号"
                                    labelCol={{span: 4}} wrapperCol={{span: 10}}
                                    >
                                        {getFieldDecorator('userName', {
                                            rules: [{
                                                //required: true, message: '请填写账号！',
                                            }],
                                        })(
                                            <span>{this.state.userName}</span>
                                        )}
                                </Form.Item>
                                <Form.Item
                                    label="旧密码"
                                    labelCol={{span: 4}} wrapperCol={{span: 10}}
                                    >
                                        {getFieldDecorator('passWord', {
                                            rules: [{
                                                required: true, message: '请填写求助名称',
                                            },{
                                                validator: this.validFunctionOld
                                            }],
                                            validateTrigger: 'onSubmit',
                                        })(
                                            <Input type='password' style={{width:'70%'}} size={'small'}/>
                                        )}
                                </Form.Item>
                                <Form.Item
                                    label="新密码"
                                    labelCol={{span: 4}} wrapperCol={{span: 10}}
                                    >
                                        {getFieldDecorator('newPassWord', {
                                            rules: [{
                                                required: true, message: '请填写求助热线!',
                                            }],
                                        })(
                                            <Input type='password' style={{width:'70%'}} size={'small'}/>
                                        )}
                                </Form.Item>
                                <Form.Item
                                    label="确认新密码"
                                    labelCol={{span: 4}} wrapperCol={{span: 10}}
                                    >
                                        {getFieldDecorator('confirmNewPassWord', {
                                            rules: [{
                                                required: true, message: '请确认新密码!',
                                            },{
                                                validator: this.validFunction
                                            }],
                                            validateTrigger: 'onSubmit',
                                        })(
                                            <Input type='password' style={{width:'70%'}} size={'small'}/>
                                        )}
                                </Form.Item>
                            </Col>
                        
                        </Row>
                        <Row>
                            <Col span={20} offset={4}>
                                <Button key="submit" type="primary" onClick={this.createAccount.bind(this)}>
                                    确认修改
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </div>
                    
        )
    }
}

export default Form.create({name: 'systemSafe'})(CreateModal);