import React, { Component } from 'react';
import { Menu } from 'antd';
import {Link} from 'react-router-dom';
import './slider.css';
const SubMenu = Menu.SubMenu;


class Slider extends Component {
    constructor(props,context){
        super(props,context);
        this.state={
            permission:[],
            permissionStr:'',
            role:'',
            menuArray:''
        }
    }
    
    componentDidMount(){
        if(window.sessionStorage.getItem('menu')!==null){
            this.setState({
                menuArray:window.sessionStorage.getItem('menu')
            })
        }
       
    }
    onOpenChange(keys){
        if(keys.length===1||keys.length===0){
            this.setState({
                keys:keys
            })
            return
        }
        const latestOpenkey=keys[keys.length-1]
        if(latestOpenkey.includes(keys[0])){
            this.setState({
                keys:keys
            })
        }else{
            this.setState({
                keys:[latestOpenkey]
            })
        }
    }
    render() {
        return (
            <div className="slider" style={{height:this.props.height,overflowY:'scroll',backgroundColor:'#001529'}}>
                <Menu
                    style={{height:this.props.height}}
                    mode="inline"
                    theme="dark"
                    inlineCollapsed={this.props.isCollapesd}
                    onOpenChange={this.onOpenChange.bind(this)}
                >   
                    {
                        this.state.menuArray.indexOf('用户管理')>-1?<Menu.Item key="1">
                        <Link to={'/index/userAdminManage'}>用户管理</Link>
                    </Menu.Item>:''
                    }
                    {
                        this.state.menuArray.indexOf('数据分析')>-1?<Menu.Item key="2">
                        <Link to={'/index/dataAnysis'}>数据分析</Link>
                    </Menu.Item>:''
                    }
                    {
                        this.state.menuArray.indexOf('首页管理')>-1
                        ||this.state.menuArray.indexOf('展示图设置')>-1
                        ||this.state.menuArray.indexOf('欢迎页设置')>-1
                        ||this.state.menuArray.indexOf('官方推荐')>-1
                       ?<SubMenu
                        key="sub1"
                        title={
                        <span>
                            
                            <span>首页管理</span>
                        </span>
                        }
                    >
                        {
                        this.state.menuArray.indexOf('展示图设置')>-1?<Menu.Item key="3">
                        <Link to={'/index/showPageManage'}>展示图设置</Link>
                    </Menu.Item>:''
                        }
                        
                        {
                        this.state.menuArray.indexOf('官方推荐')>-1?<Menu.Item key="4">
                        <Link to={'/index/recommendManage'}>官方推荐</Link>
                    </Menu.Item>:''
                        } 
                    </SubMenu>:''
                    }
                    {
                        this.state.menuArray.indexOf('艺术体验管理')>-1? <Menu.Item key="18">
                        <Link to={'/index/artManage'}>艺术体验管理</Link>
                    </Menu.Item>:''
                        }
                    {
                        this.state.menuArray.indexOf('景点管理')>-1? <Menu.Item key="5">
                        <Link to={'/index/viewManage'}>景点管理</Link>
                    </Menu.Item>:''
                        }
                        {
                        this.state.menuArray.indexOf('酒店管理')>-1? <Menu.Item key="6">
                        <Link to={'/index/hotelManage'}>酒店管理</Link>
                    </Menu.Item>:''
                        }
                         {
                        this.state.menuArray.indexOf('美食管理')>-1? <Menu.Item key="7">
                        <Link to={'/index/foodManage'}>美食管理</Link>
                    </Menu.Item>:''
                        }
                        {
                        this.state.menuArray.indexOf('购物管理')>-1? <Menu.Item key="8">
                        <Link to={'/index/shoppingManage'}>购物管理</Link>
                    </Menu.Item>:''
                        }
                       
                        
                        {
                        this.state.menuArray.indexOf('玩乐管理')>-1? <Menu.Item key="9">
                        <Link to={'/index/leisureManage'}>玩乐管理</Link>
                    </Menu.Item>:''
                        }
                         {
                        this.state.menuArray.indexOf('交通管理')>-1? <Menu.Item key="10">
                        <Link to={'/index/trafficManage'}>交通管理</Link>
                    </Menu.Item>:''
                        }
                        
                    {
                        this.state.menuArray.indexOf('旅行路线管理')>-1? <Menu.Item key="11">
                        <Link to={'/index/visitManage'}>旅行路线管理</Link>
                    </Menu.Item>:''
                        }
                    {
                        this.state.menuArray.indexOf('旅行指南管理')>-1? <Menu.Item key="12">
                        <Link to={'/index/guideManage'}>旅行指南管理</Link>
                    </Menu.Item>:''
                        }
                    {
                        this.state.menuArray.indexOf('标签分类管理')>-1? <Menu.Item key="13">
                        <Link to={'/index/tagManage'}>标签分类管理</Link>
                    </Menu.Item> :''
                        }
                    {
                        this.state.menuArray.indexOf('求助管理')>-1?<Menu.Item key="14">
                        <Link to={'/index/helpManage'}>求助管理</Link>
                    </Menu.Item>:''
                        }
                    {
                        this.state.menuArray.indexOf('管理员管理')>-1?<Menu.Item key="15">
                        <Link to={'/index/superAdminManage'}>管理员管理</Link>
                    </Menu.Item> :''
                        }
                    {
                        this.state.menuArray.indexOf('后台登录展示图')>-1?<Menu.Item key="16">
                        <Link to={'/index/indexShowPage'}>后台登录展示图</Link>
                    </Menu.Item> :''
                        }
                    {
                        this.state.menuArray.indexOf('系统安全')>-1?<Menu.Item key="17">
                        <Link to={'/index/systemSafeManage'}>系统安全</Link>
                    </Menu.Item>:''
                        }
                      
                </Menu> 
            </div>
        );
    }
}

export default Slider;