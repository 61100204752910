import React from 'react'
import {Row, Col, Input, Button,Modal,Form} from 'antd'
const { TextArea } = Input;
class CreateModal extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            
        }
    }

    createAccount= (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.props.changeVisible(false,values);
                this.props.form.resetFields();
            }
        });
    }
    cancelCreateAccount(){
        this.props.form.resetFields();
        this.props.changeVisible(false,'');
    }
    
  
    render() {
        const {getFieldDecorator} = this.props.form;
        return (
            <Modal
                    maskClosable={false}
                    destroyOnClose={true}
                    visible={this.props.visible}
                    title={"新增信息"}
                    onOk={this.createAccount.bind(this)}
                    onCancel={this.cancelCreateAccount.bind(this)}
                    footer={[
                        <Button key="back" onClick={this.cancelCreateAccount.bind(this)}>
                        取消
                        </Button>,
                        <Button key="submit" type="primary" onClick={this.createAccount.bind(this)}>
                        确定
                        </Button>,
                    ]}>
                    <Form>
                        <Row>
                            <Col span={24}>
                                <Form.Item
                                    label="信息标题"
                                    labelCol={{span: 4}} wrapperCol={{span: 19}}
                                    >
                                        {getFieldDecorator('viewTitle', {
                                            rules: [{
                                                required: true, message: '请填写信息标题!',
                                            }],
                                        })(
                                            <Input style={{width:'70%'}} size={'small'}/>
                                        )}
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    label="信息内容"
                                    labelCol={{span: 4}} wrapperCol={{span: 19}}
                                    >
                                        {getFieldDecorator('viewContent', {
                                            rules: [{
                                                required: true, message: '请填写信息内容!',
                                            }],
                                        })(
                                            <TextArea size={'small'} rows={4} />
                                        )}
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                    
                </Modal>    
        )
    }
}

export default Form.create({name: 'addViewInfo'})(CreateModal);