import React from 'react'
import {Row, Col, Input, Button,Modal,Form,Select} from 'antd'
const { TextArea } = Input
const {Option} = Select
class CreateModal extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            
        }
    }

    createAccount= (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.props.changeVisible(false,values);
                this.props.form.resetFields();
            }
        });
    }
    cancelCreateAccount(){
        this.props.form.resetFields();
        this.props.changeVisible(false,'');
    }
    
  
    render() {
        const {getFieldDecorator} = this.props.form;
        const spotData=this.props.spotData
        const dayData=this.props.dayData
        const tripData=this.props.tripData
        let tripDataId=[]
        tripData.map((item,index)=>{
            tripDataId.push(item.spotId)
            return item
        })
        let spot=[];
        spotData.map((item,index)=>{
            if(tripDataId.indexOf(item.id)<0){
                spot.push(item)
            }
            return item
        }) 
        return (
            <Modal
                    maskClosable={false}
                    destroyOnClose={true}
                    visible={this.props.visible}
                    title={"新增行程"}
                    onOk={this.createAccount.bind(this)}
                    onCancel={this.cancelCreateAccount.bind(this)}
                    footer={[
                        <Button key="back" onClick={this.cancelCreateAccount.bind(this)}>
                        取消
                        </Button>,
                        <Button key="submit" type="primary" onClick={this.createAccount.bind(this)}>
                        确定
                        </Button>,
                    ]}>
                    <Form>
                        <Row>
                            <Col span={24}>
                                <Form.Item
                                    label="景点"
                                    labelCol={{span: 4}} wrapperCol={{span: 19}}
                                    >
                                        {getFieldDecorator('spotId', {
                                            rules: [{
                                                required: true, message: '请选择景点!',
                                            }],
                                        })(
                                            <Select
                                                style={{width:'70%'}}
                                                size={'small'}
                                                showSearch={true}
                                                optionFilterProp="children"
                                            >
                                                {
                                                    spot.map((item,index)=>{
                                                        return <Option value={item.id}>{item.zhName}</Option>
                                                    })
                                                }
                                            </Select>
                                        )}
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    label="行程规划"
                                    labelCol={{span: 4}} wrapperCol={{span: 19}}
                                    >
                                        {getFieldDecorator('dayNum', {
                                            rules: [{
                                                required: true, message: '请选择行程规划!',
                                            }],
                                        })(
                                            <Select
                                                style={{width:'70%'}}
                                                size={'small'}
                                                showSearch={true}
                                                optionFilterProp="children"
                                            >
                                                {
                                                    dayData.map((item,index)=>{
                                                        return <Option value={item.value}>{item.name}</Option>
                                                    })
                                                }
                                            </Select>
                                        )}
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    label="游玩时间"
                                    labelCol={{span: 4}} wrapperCol={{span: 19}}
                                    >
                                        {getFieldDecorator('time', {
                                            rules: [{
                                                required: true, message: '请填写游玩时间!',
                                            }],
                                        })(
                                            <Input  style={{width:'30%'}} size={'small'}/>
                                        )}
                                        <span style={{marginLeft:'10px'}}>小时</span>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    label="推荐理由"
                                    labelCol={{span: 4}} wrapperCol={{span: 19}}
                                    >
                                        {getFieldDecorator('content', {
                                            rules: [{
                                                required: true, message: '请填写推荐理由!',
                                            }],
                                        })(
                                            <TextArea  rows={4} />
                                        )}
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                    
                </Modal>    
        )
    }
}

export default Form.create({name: 'addTrip'})(CreateModal);