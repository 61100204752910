import React from 'react'
import './index.css'
import { Row, Col } from 'antd'
class Title extends React.Component{
    render(){
        return(
            <div className='titleComponent'>
                <Row style={{padding:'15px 0'}}>
                    <Col xl={24} lg={24}>
                        <span className='titleSpan'>{this.props.titleMsg.titleName}</span>
                    </Col>
                </Row>
            </div>
        )
    }
}

export default Title