import React from 'react'
import { Row, Col, Button, DatePicker } from 'antd'

const { RangePicker } = DatePicker;
class SearchGroup extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            SCENIC_SPOT: 'SCENIC_SPOT',
            date: [],
            keyValue:new Date()
        }
    }
    dateChange(date, dateString) {
        this.props.dateChange(dateString)
    }
    onChangeResultDate(value) {
        this.setState({
            SCENIC_SPOT: value
        })
        this.props.resultDateSearch(value)
    }

    searchList() {
        this.props.searchList()
    }
    reset() {
        this.setState({
            SCENIC_SPOT: 'SCENIC_SPOT',
            keyValue:new Date()
        })
        this.props.resetChange()
    }

    render() {
        return (
            <Row style={{ padding: '10px 20px' }}>
                <Col xl={10} lg={10} md={10}>
                    <label style={{ marginRight: '5px' }}>时间：</label>
                    <RangePicker key = {this.state.keyValue} size={'small'} onChange={this.dateChange.bind(this)} style={{ width: '80%' }} showTime />
                </Col>
                <Col xl={5} lg={5} md={5}>
                    <Button type="primary" onClick={this.searchList.bind(this)} style={{ marginRight: '10px' }}>查询</Button>
                    <Button onClick={this.reset.bind(this)}>重置</Button>
                </Col>
            </Row>
        )
    }
}

export default SearchGroup