import React from 'react'
import Login from '../components/login'
import IndexPage from '../components/index'
import {Switch, Route } from 'react-router-dom';

class Index extends React.Component {
    render() {
        return (
                <Switch>
                    <Route path="/" exact component={Login} />
                    <Route path='/login' component={Login} />
                    <Route path='/index' component={IndexPage} />
                </Switch>

        )
    }
}

export default Index