import React, { Component } from 'react';
import axios from '../../axios'
import TableGroup from '../../base/table1/index'
import { beforeRouterEach } from '../../common/index'
import Title from '../../base/title/index'
import { message, Tabs, Modal, Icon, Divider,Button } from 'antd';
const { TabPane } = Tabs;
const { confirm } = Modal;

class Index extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            titleMsg: {
                titleName: '官方推荐',
            },
            table: {
                hasHeight: true,
                isPageShow: true,
                current: 1,
                totalCount: 0,
                columns: [
                    {
                        title: '排序',
                        dataIndex: 'column',
                        render: (text, record, index) => (
                            <span>
                                <span className='tableBtn' onClick={(event) => {
                                    this.toTop(record)
                                    event.stopPropagation()
                                }}><Button type="link">置顶</Button></span><Divider type="vertical" />
                                <span className='tableBtn' onClick={(event) => {
                                    this.seqUp(record, index)
                                    event.stopPropagation()
                                }}><Icon type="arrow-up" /></span><Divider type="vertical" />
                                <span className='tableBtn' onClick={(event) => {
                                    this.seqDown(record, index)
                                    event.stopPropagation()
                                }}><Icon type="arrow-down" /></span>
                            </span>
                        ),
                    },
                    {
                        title: '名称',
                        dataIndex: 'zhName',
                    },
                    {
                        title: '操作',
                        key: 'action',
                        render: (text, record) => (
                            <span>
                                <span className='tableBtn' onClick={(event) => {
                                    this.cancel(record)
                                    event.stopPropagation()
                                }}>取消</span>
                            </span>
                        ),
                    }
                ],
                data: [],
                isSelection: false
            },
            tableParams: {
                rows: 10,
                page: 1,
                type: 'SCENIC_SPOT',
                recommend: 1
            },
            selected: [],
            nextScroll: 39
        }
    }
    toTop(record) {
        axios.gets('/api/v1/scenic/spot/sticky', { id: record.id })
            .then((res) => {
                if (res.status === 200) {
                    message.success('置顶成功！')
                    this.getDataList()
                } else {
                    message.error(res.message)
                }
            })
    }
    seqDown(record, index) {
        let length = index + 1;
        if (length < this.state.table.data.length) {
            let sort1 = this.state.table.data[length].sort;
            let sort2 = record.sort;
            record.sort = sort1;
            let downRow = this.state.table.data[length];
            downRow.sort = sort2;
            axios.posts('/api/v1/scenic/spot/modify', record)
                .then((res) => {
                    if (res.status === 200) {
                        axios.posts('/api/v1/scenic/spot/modify', downRow)
                            .then((res) => {
                                if (res.status === 200) {
                                    message.success('操作成功！')
                                    this.getDataList()
                                } else {
                                    message.error(res.message)
                                }
                            })
                    } else {
                        message.error(res.message)
                    }
                })
        }

    }
    seqUp(record, index) {
        if (index > 0) {
            let sort1 = this.state.table.data[index - 1].sort;
            let sort2 = record.sort;
            record.sort = sort1;
            let upRow = this.state.table.data[index - 1];
            upRow.sort = sort2;
            axios.posts('/api/v1/scenic/spot/modify', record)
                .then((res) => {
                    if (res.status === 200) {
                        axios.posts('/api/v1/scenic/spot/modify', upRow)
                            .then((res) => {
                                if (res.status === 200) {
                                    message.success('操作成功！')
                                    this.getDataList()
                                } else {
                                    message.error(res.message)
                                }
                            })
                    } else {
                        message.error(res.message)
                    }
                })
        }
    }
    cancel(record) {
        let _this = this;
        confirm({
            title: '是否取消当前数据为官方推荐?',
            content: '',
            onOk() {
                record.recommend = 0;
                axios.posts('/api/v1/scenic/spot/modify', record)
                    .then((res) => {
                        if (res.status === 200) {
                            message.success('操作成功！')
                            _this.getDataList()
                        } else {
                            message.error(res.message)
                        }
                    })
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }

    getDataList() {
        axios.gets('/api/v1/scenic/spot/find', this.state.tableParams)
            .then((res) => {
                if (res.status === 200) {
                    let data = Object.assign({}, this.state.table, {
                        data: res.data.records,
                        totalCount: res.data.total,
                    });
                    this.setState({
                        table: data
                    })
                } else {
                    message.error(res.message)
                }
            })
    }

    selectedChange(val) {
        this.setState({
            selected: val
        })
    }
    tableSizeChange(current, pageSize) {
        let data = Object.assign({}, this.state.table, {
            current: current,
        });
        this.setState({
            table: data
        });
        let data2 = Object.assign({}, this.state.tableParams, {
            rows: pageSize,
        });
        this.setState({
            tableParams: data2
        }, () => {
            this.getDataList();
        });

    }
    tablePageChange(page) {
        let data = Object.assign({}, this.state.table, {
            current: page,
        });
        this.setState({
            table: data
        });
        let data2 = Object.assign({}, this.state.tableParams, {
            page: page,
        });
        this.setState({
            tableParams: data2
        }, () => {
            this.getDataList();
        });
    }

    componentDidMount() {
        beforeRouterEach(this)
        this.getDataList();
        const tableBox = document.getElementById('table-box')
        tableBox.addEventListener('scroll', this.debounce(() => {
            if (tableBox.scrollTop>=this.state.nextScroll) {
                let obj=this.state.tableParams;
                obj.rows+=20;

                axios
                    .gets('/api/v1/scenic/spot/find', this.state.tableParams)
                    .then((res) => {
                        if (res.status === 200) {
                            let data = Object.assign({}, this.state.table, {
                                data: res.data.records,
                                totalCount: res.data.total,
                            })

                            this.setState({
                                table: data,
                            })

                            let nextScroll=this.state.nextScroll*2;

                            this.setState({
                                nextScroll,
                                tableParams:obj
                            })

                            message.success('加载成功');
                        } else {
                            message.error(res.message)
                        }
                    })
            }
        },500))
    }

    debounce(fn,wait){
        var timer = null;
        return function(){
            if(timer !== null){
                clearTimeout(timer);
            }
            timer = setTimeout(fn,wait);
        }
    }


    callback(key) {
        let type = '';
        if (key === '1') {
            type = 'SCENIC_SPOT'
        } else if (key === '2') {
            type = 'SHOPPING'
        } else if (key === '3') {
            type = 'DELICIOUS_FOOD'
        } else if (key === '4') {
            type = 'HOTEL'
        } else if (key === '5') {
            type = 'LEISURE_TIME'
        } else if (key === '6') {
            type = 'TRAFFIC'
        } else if (key === '18') {
            type = 'ART'
        }
        let data2 = Object.assign({}, this.state.tableParams, {
            page: 1,
            rows: 10,
            type: type
        });
        this.setState({
            nextScroll:39,
            tableParams: data2
        }, () => {
            this.getDataList();
        });
    }

    render() {
        return (
            <div className="tagManage">
                <Title titleMsg={this.state.titleMsg} />

                <Tabs defaultActiveKey="1" style={{ margin: '0 20px' }} onChange={this.callback.bind(this)} type="card">
                    <TabPane tab="景点" key="1">

                    </TabPane>
                    <TabPane tab="购物" key="2">

                    </TabPane>
                    <TabPane tab="美食" key="3">

                    </TabPane>
                    <TabPane tab="酒店" key="4">

                    </TabPane>
                    <TabPane tab="玩乐" key="5">

                    </TabPane>

                    <TabPane tab="交通" key="6">

                    </TabPane>

                    <TabPane tab="艺术体验" key="18">

                    </TabPane>
                </Tabs>

                <TableGroup
                    selectedChange={this.selectedChange.bind(this)}
                    pageChange={this.tablePageChange.bind(this)}
                    sizeChange={this.tableSizeChange.bind(this)}
                    table={this.state.table} />


            </div>
        );
    }
}

export default Index;
