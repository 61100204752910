import React from 'react'
import { message, Row, Col, Button, Modal } from 'antd'
import axios from '../../axios/index'
import './index.css'
import Cropper from 'react-cropper'
import 'cropperjs/dist/cropper.css'
class UploadBox extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            selectedImageFile: {},
            editImageModalVisible: false,
            imgSrc: ''
        }
    }
    uploadpic() {
        this.cropper.getCroppedCanvas().toBlob(blob => {
            let filename = this.state.selectedImageFile[0].name
            // 创造提交表单数据对象
            const formData = new FormData()
            // 添加要上传的文件
            formData.append('file', blob, filename)
            axios.uploadPhoto('/file/upload', formData, (res) => { })
                .then(response => {
                    if (response.status === 200) {
                        message.success('上传成功！')
                        this.props.getImage(response.data)
                        this.setState({
                            selectedImageFile: {},
                            editImageModalVisible: false,
                            imgSrc: ''
                        })
                    } else {
                        message.error('上传失败！')
                    }
                }, response => {
                    message.error('上传失败！')
                })

        })


    }
    handleInputChange(e) {
        let file = e.target.files;
        console.log(file[0].type)
        if (file[0].type === 'image/jpg' || file[0].type === 'image/jpeg' || file[0].type === 'image/png') {
            if ((file[0].size / 1024) <= 1024) {
                this.setState({
                    selectedImageFile: file,
                }, () => {
                    const fileReader = new FileReader()
                    fileReader.readAsDataURL(this.state.selectedImageFile[0])
                    fileReader.onload = (e) => {
                        const dataURL = e.target.result
                        this.setState({
                            imgSrc: dataURL
                        }, () => {
                            this.setState({
                                editImageModalVisible: true,
                            })
                        })
                    }
                })
            } else {
                message.warning('图片大小不能超过1M！')
            }

        } else {
            message.warning('图片格式必须为jpg或png！')
        }


    }
    fileClick(e) {
        document.getElementsByClassName('upload_file')[0].click()
    }
    fileDel(e) {
        // $(e.target).parents('.upload_img').find('input').val('')
        this.data.pictureUrl = "";
    }
    cancelUpload() {
        this.setState({
            selectedImageFile: {},
            editImageModalVisible: false,
            imgSrc: ''
        })
    }
    render() {
        return (
            <div className="upload_img">

                <div className="upload_body">
                    <div className="upload_body_center">
                        <div className="upload_addZoo" onClick={this.fileClick.bind(this)}>+</div>
                    </div>
                </div>

                <input
                    name="file"
                    accept="image/png, image/jpeg, image/jpg"
                    onChange={this.handleInputChange.bind(this)}
                    type="file"
                    className="upload_file"
                    style={{ display: 'none' }} />
                <Modal
                    maskClosable={false}
                    destroyOnClose={true}
                    visible={this.state.editImageModalVisible}
                    title={"编辑图片"}
                    onOk={this.uploadpic.bind(this)}
                    onCancel={this.cancelUpload.bind(this)}
                    footer={[
                        <Button key="submit" type="primary" onClick={this.uploadpic.bind(this)}>
                            确定
                        </Button>
                    ]}>

                    <Row>
                        <Col span={24}>
                            <Cropper
                                src={this.state.imgSrc}
                                className="company-logo-cropper"
                                ref={cropper => this.cropper = cropper}
                                style={{ height: 400, width: '100%' }}
                                // Cropper.js options
                                autoCropArea={1}
                                background={false}
                                viewMode={1}
                                zoomable={true}
                                guides={false}
                                preview='.cropper-preview'
                            />
                        </Col>
                    </Row>


                </Modal>
            </div>
        )
    }
}

export default UploadBox;