import React, { Component } from 'react';
import axios from '../../axios'
import {Avatar, message} from 'antd';
import TableGroup from '../../base/table/index'
import SearchGroup from './searchGroup'
import Title from '../../base/title/index'
import {beforeRouterEach} from '../../common/index'
import './index.css'

class Index extends Component {
    constructor(props,context){
        super(props,context);
        this.state={
            titleMsg: {
                titleName: '用户管理',
            },
            table: {
                hasHeight: true,
                isPageShow: true,
                current: 1,
                totalCount: 0,
                columns: [
                    {
                        title:'微信头像',
                        dataIndex:'avatarUrl',
                        render: (text, record) => <span> 
                            <Avatar src={record.avatarUrl} />
                            </span>
                    },
                    {
                        title: '微信昵称',
                        dataIndex: 'nickName',
                    }, {
                        title: '性别',
                        dataIndex: 'gender',
                        render: (text, record) => (
                            <span>
                                {record.gender===1?'男':record.gender===2?'女':'未知'}
                        </span>
                        ),
                    },{
                        title: '首次登陆时间',
                        dataIndex: 'loginTime' 
                    },  
                    // {
                    //     title: '操作',
                    //     key: 'action',
                    //     render: (text, record) => (
                    //         <span>
                    //             <span>
                    //                 {
                    //                     record.status!==1?<span className='tableBtn' onClick={(event) => {
                                           
                    //                         event.stopPropagation()
                    //                     }}>启用</span>:<span className='tableBtn' onClick={(event) => {
                                          
                    //                         event.stopPropagation()
                    //                     }}>禁用</span>
                    //                 }
                                   
                    //             </span>
                    //     </span>
                    //     ),
                    // }
                ],
                data: [],
                isSelection: false
            },
            tableParams:{
                rows:10,
                page:1,
                phone:'',
                nickName:'',
                gender:''
            }
        }
    }
   
    getDataList(){
        axios.gets('/api/v1/wx/user/find',this.state.tableParams)
            .then((res)=>{
                if(res.status===200){
                    let data = Object.assign({}, this.state.table, {
                        data: res.data.records,
                        totalCount: res.data.total,
                    });
                    this.setState({
                        table: data
                    })
                }else{
                    message.error(res.message)
                }
            })
       
    }
    selectedChange(val){
        console.log(val)
    }
    tableSizeChange(current, pageSize) {
        let data = Object.assign({}, this.state.table, {
            current: current,
        });
        this.setState({
            table: data
        });
        let data2 = Object.assign({}, this.state.tableParams, {
            rows: pageSize,
        });
        this.setState({
            tableParams: data2
        }, () => {
            this.getDataList();
        });

    }
    tablePageChange(page) {
        let data = Object.assign({}, this.state.table, {
            current: page,
        });
        this.setState({
            table: data
        });
        let data2 = Object.assign({}, this.state.tableParams, {
            page: page ,
        });
        this.setState({
            tableParams: data2
        }, () => {
            this.getDataList();
        });
    }
    resultDateSearch(value) {
        let data = Object.assign({}, this.state.table, {
            current: 1,
        });
        this.setState({
            table: data
        });
        let data2 = Object.assign({}, this.state.tableParams, {
            rows:10,
            page:1,
            phone:value,
        });
        this.setState({
            tableParams: data2
        });
    }
    resetChange(){
        let data = Object.assign({}, this.state.table, {
            current: 1,
        });
        this.setState({
            table: data
        });
        let data2 = Object.assign({}, this.state.tableParams, {
            rows:10,
            page:1,
            phone:'',
            nickName:'',
            gender:''
        });
        this.setState({
            tableParams: data2
        }, () => {
            this.getDataList();
        });
    }
    searchList(){
        this.getDataList();
    }
    orderDateSearch(value) {
        let data = Object.assign({}, this.state.table, {
            current: 1,
        });
        this.setState({
            table: data
        });
        let data2 = Object.assign({}, this.state.tableParams, {
            rows:10,
            page:1,
            nickName:value,
        });
        this.setState({
            tableParams: data2
        });
    }
    optionsChangeSex(value){
        let data = Object.assign({}, this.state.table, {
            current: 1,
        });
        this.setState({
            table: data
        });
        let data2 = Object.assign({}, this.state.tableParams, {
            rows:10,
            page:1,
            gender:parseInt(value),
        });
        this.setState({
            tableParams: data2
        });
    }
    componentDidMount(){
        beforeRouterEach(this)
        this.getDataList();
    }
    render() {
        return (
            <div className="userAccountManage">
                <Title titleMsg={this.state.titleMsg}/> 
                <SearchGroup
                    resetChange={this.resetChange.bind(this)}
                    orderDateSearch={this.orderDateSearch.bind(this)}
                    resultDateSearch={this.resultDateSearch.bind(this)}
                    optionsChangeSex={this.optionsChangeSex.bind(this)}
                    searchList={this.searchList.bind(this)}
               />  
              
               <TableGroup
                    selectedChange={this.selectedChange.bind(this)}
                    pageChange={this.tablePageChange.bind(this)}
                    sizeChange={this.tableSizeChange.bind(this)}
                    table={this.state.table}/>
            </div>
        );
    }
}

export default Index;