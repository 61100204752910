import React from 'react'
import {Row, Col, Table, Pagination} from 'antd'
import './index.css'

class TableGroup extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            height:500
        }
    }

    onPageChange(pageNumber) {
        this.props.pageChange(pageNumber);
    }

    onShowSizeChange(current, pageSize) {
        this.props.sizeChange(current, pageSize);
    }
    componentDidMount(){
        let _height=window.innerHeight;
        this.setState({
            height:_height-187
        });
    }
    onSelectChange = selectedRowKeys => {
        this.props.selectedChange(selectedRowKeys)
    };
    render() {
        const columns = this.props.table.columns;
        const data = this.props.table.data;
        let boxHeight=0;
        if(this.props.table.hasHeight){
            boxHeight=window.innerHeight-244
        }

        return (
            <Row style={{height:boxHeight===0?this.state.height:boxHeight}}>
                <Col span={24} id='table-box' className='tableComponent' style={{padding: '10px 20px',height:'450px',overflowY:"auto"}}>
                    <Table
                        style={{maxHeight:boxHeight===0?this.state.height:boxHeight}}
                        // selection={false}
                        rowSelection={{
                            onChange: this.onSelectChange.bind(this),
                        }}
                        rowKey={record => record.id}
                        pagination={false}
                        columns={columns}
                        dataSource={data}/>
                </Col>
                <div style={{position:'absolute',padding: '10px 20px',textAlign:'center',width:'100%',bottom:'10px'}}>
                    <Pagination
                        size="small"
                        pageSizeOptions={this.props.pageSize}
                        //showSizeChanger
                        //showQuickJumper
                        style={{ display: this.props.table.isPageShow ? 'block' : 'none'}}
                        current={this.props.table.current}
                        onChange={this.onPageChange.bind(this)}
                        onShowSizeChange={this.onShowSizeChange.bind(this)}
                        total={this.props.table.totalCount}
                        showTotal={total => `总共 ${total} 个项目`}
                    />
                </div>
            </Row>
        )
    }
}

export default TableGroup
