import React from 'react'
import {Row, Col, Input, Select, Button,Modal,Form,Table,Divider,InputNumber,Icon  } from 'antd'
import UploadBox from '../../../base/upload/index'
import AddTripInfo from '../addTrip/index'
import ModifyTripInfo from '../modifyTrip/index'
const {Option} = Select
const { TextArea } = Input

class CreateModal extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            addTripVisible:false,
            modifyTripVisible:false,
            modifyTripData:{},
            infoColumns:[
                {
                    title:'排序',
                    dataIndex:'column',
                    width:100,
                    render: (text, record,index) => (
                        <span>
                                <span className='tableBtn' onClick={(event) => {
                                        this.seqUpTrip(record,index)
                                        event.stopPropagation()
                                    }}><Icon type="arrow-up" /></span><Divider type="vertical"/>
                                     <span className='tableBtn' onClick={(event) => {
                                        this.seqDownTrip(record,index)
                                        event.stopPropagation()
                                    }}><Icon type="arrow-down" /></span>
                    </span>
                    ),
                },
                {
                    title: '行程',
                    dataIndex: 'dayNum', 
                    render: (text, record) => (
                        <span>
                            第{record.dayNum}天
                        </span>
                    )
                  },
                {
                  title: '景点名称',
                  dataIndex: 'name',
                  width:150,
                },
                {
                  title: '推荐理由',
                  dataIndex: 'content', 
                },
                {
                    title: '游玩时间(小时)',
                    dataIndex: 'time', 
                    width:150,
                  },
                {
                  title: '操作',
                  key: 'action',
                  width:150,
                  render: (text, record) => (
                      <span>
                          <span>
                          <span className='tableBtn' onClick={(event) => {
                                      this.modifyTripInfo(record)
                                      event.stopPropagation()
                                  }}>编辑</span><Divider type="vertical"/>
                              <span className='tableBtn' onClick={(event) => {
                                      this.delTrip(record)
                                      event.stopPropagation()
                                  }}>删除</span>
                              
                          
                          </span>
                      </span>
                  ),
                },
          ],
        }
    }

    createAccount= (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.props.changeVisible(false,values);
                this.props.form.resetFields();
            }
        });
    }
    cancelCreateAccount(){
        this.props.form.resetFields();
        this.props.changeVisible(false,'');
    }
    getImage(val){
        this.props.getImage(val)
    }
    delImage(value){
        this.props.delImage(value)
    }
    delInfo(record){
        this.props.changeDelInfoVisible(record.key)
    }
   
    addTrip(){
        this.setState({
            addTripVisible:true
        })
    }
    modifyTripInfo(record){
        this.setState({
            modifyTripData:record
        },()=>{
            this.setState({
                modifyTripVisible:true
            })
        })
    }
    delTrip(record){
        this.props.changeDelTripVisible(record.key)
    }
    seqDownTrip(record){
        this.props.seqDownTrip(record)
    }
    seqUpTrip(record){
        this.props.seqUpTrip(record)
    }
    changeTripVisible(val,values){
        this.setState({
            addTripVisible:val
        })
        this.props.form.setFieldsValue({
            trip:'has'
        })
        this.props.changeTripVisible(values)
    }
    changeModifyTripVisible(val,values){
        this.setState({
            modifyTripVisible:val
        })
        if(values!==''){
            values.key=this.state.modifyTripData.key  
        } 
        this.props.changeModifyTripVisible(values)
    }
    changeDay(val){
        let value=''
        if(val===''){
            value=1
        }else{
            value=val
        }
        this.props.changeDay(value)
    }
    render() {
        const {getFieldDecorator} = this.props.form;
        const images=this.props.images
        const tagData=this.props.tagData
        const tripData=this.props.tripData
        const spotData=this.props.spotData
        const dayData=this.props.dayData
        //const typeData=this.props.typeData
        return (
            <Modal
                    maskClosable={false}
                    destroyOnClose={true}
                    style={{ top: 20 }}
                    width={1000}
                    visible={this.props.visible}
                    title={"新增"}
                    onOk={this.createAccount.bind(this)}
                    onCancel={this.cancelCreateAccount.bind(this)}
                    footer={[
                        <Button key="back" onClick={this.cancelCreateAccount.bind(this)}>
                        取消
                        </Button>,
                        <Button key="submit" type="primary" onClick={this.createAccount.bind(this)}>
                        确定
                        </Button>,
                    ]}>
                    <Form>
                        <Row style={{height:'550px',overflowY:'auto'}}>
                            <Col span={24}>
                                <Form.Item
                                    label="路线名称"
                                    labelCol={{span: 4}} wrapperCol={{span: 15}}
                                    >
                                        {getFieldDecorator('name', {
                                            rules: [{
                                                required: true, message: '请填写路线名称!',
                                            }],
                                        })(
                                            <Input  style={{width:'70%'}} size={'small'}/>
                                        )}
                                </Form.Item>
                            </Col>
                            {/* <Col span={24}>
                                <Form.Item
                                    label="路线分类"
                                    labelCol={{span: 4}} wrapperCol={{span: 15}}
                                    >
                                        {getFieldDecorator('type', {
                                        rules: [{
                                            required: true, message: '请选择路线分类!',
                                        }],
                                       
                                    })(
                                        <Select
                                            style={{width:'70%'}}
                                            size={'small'}
                                        >   
                                        {
                                            typeData.map((item,index)=>{
                                                return <Option key={index} value={item.id}>{item.name}</Option>
                                            })
                                        }
                                        </Select>
                                    )}
                                </Form.Item>
                            </Col> */}
                            <Col span={24}>
                                <Form.Item
                                    label="轮播图"
                                    labelCol={{span: 4}} wrapperCol={{span: 20}}
                                >
                                    {getFieldDecorator('photo', {
                                        initialValue: images.join(','),
                                        rules: [{
                                            required: true, message: '请上传图片!',
                                        }],
                                    })(
                                        <span style={{display:'none'}}></span>
                                    )}
                                    
                                   
                                   <Row>
                                        <Col span={24}>
                                        {
                                            images.map((item,index)=>{
                                                return  <div key={index} style={{width:'126px',height:'95px',display:'inline-block',marginLeft:'10px',backgroundSize:'100% 100%',backgroundImage:'url('+ item +')'}}>
                                                <div style={{cursor:'pointer',display:'inline',float:'right',lineHeight:'20px'}} onClick={this.delImage.bind(this,index)}>
                                                    <img src={[require("../../../assets/image/goodsFiles/del.png")]} alt="" />
                                                </div>
                                            
                                            </div>
                                            })
                                        }
                                        {
                                            images.length>=6?'':<UploadBox getImage={this.getImage.bind(this)}/>
                                        }
                                        </Col>
                                    </Row>
                                   <Row>
                                        <Col span={24}>
                                        <p>1. 图片宽高比要求16:9,推荐尺寸为750*360</p>
                                        <p>2. jpg格式,大小300k-1M(在保证清晰度的情况下尽可能压缩)</p>
                                        <p>3. 最少一张,最多六张</p>
                                        </Col>
                                    </Row>
                                </Form.Item>
                            </Col> 
                                   
                            <Col span={12} >
                                <Form.Item
                                label="路线分类"
                                labelCol={{span: 8}} wrapperCol={{span: 15}}
                                >
                                    {getFieldDecorator('label', {
                                        rules: [{
                                            required: true, message: '请选择路线分类!',
                                        }],
                                       
                                    })(
                                        <Select
                                            style={{width:'70%'}}
                                            size={'small'}
                                        >   
                                        {
                                            tagData.map((item,index)=>{
                                                return <Option key={index} value={item.id}>{item.name}</Option>
                                            })
                                        }
                                        </Select>
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={12} >
                                <Form.Item
                                label="热门路线"
                                labelCol={{span: 4}} wrapperCol={{span: 15}}
                                >
                                    {getFieldDecorator('hot', {
                                        rules: [{
                                            required: true, message: '请选择是否热门路线!',
                                        }],
                                    })(
                                        <Select
                                        style={{width:'70%'}}
                                        size={'small'}
                                    >
                                        <Option value="0">否</Option>
                                        <Option value="1">是</Option>
                                    </Select>
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="路线天数"
                                    labelCol={{span: 8}} wrapperCol={{span: 15}}
                                    >
                                        {getFieldDecorator('day', {
                                            initialValue: 1,
                                            rules: [{
                                                required: true, message: '请填写路线天数!',
                                            }],
                                        })(
                                            <InputNumber onChange={this.changeDay.bind(this)} style={{width:'70%'}} size={'small'}/>
                                        )}
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="景点数量"
                                    labelCol={{span: 4}} wrapperCol={{span: 15}}
                                    >
                                        {getFieldDecorator('number', {
                                            rules: [{
                                                required: true, message: '请填写景点数量!',
                                            }],
                                        })(
                                            <Input  style={{width:'70%'}} size={'small'}/>
                                        )}
                                </Form.Item>
                            </Col>
                            
                            <Col span={24} >
                                <Form.Item
                                label="路线介绍"
                                labelCol={{span: 4}} wrapperCol={{span: 15}}
                                >
                                    {getFieldDecorator('remark', {
                                        rules: [{
                                            required: true, message: '请填写介绍!',
                                        }],
                                    })(
                                        <TextArea  rows={4} />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={24} >
                                <Form.Item
                                label="路线行程"
                                labelCol={{span: 4}} wrapperCol={{span: 20}}
                                >
                                    {getFieldDecorator('trip', {
                                        rules: [{
                                            required: true, message: '请编辑路线行程!',
                                        }],
                                    })(
                                        <span style={{display:'none'}}></span>
                                    )}
                                    <Row>
                                        <Col span={24}>
                                            <Button type="primary" onClick={this.addTrip.bind(this)}>
                                                添加路线行程
                                            </Button>
                                        </Col>
                                        <Col span={24}>
                                            <Table pagination={false} columns={this.state.infoColumns} dataSource={tripData} />
                                        </Col>
                                    </Row>
                                </Form.Item>
                            </Col>
                            <AddTripInfo
                                spotData={spotData}
                                dayData={dayData}
                                tripData={tripData}
                                visible={this.state.addTripVisible}
                                changeVisible={this.changeTripVisible.bind(this)}
                            />     
                            <ModifyTripInfo
                                spotData={spotData}
                                dayData={dayData}
                                detailData={this.state.modifyTripData}
                                visible={this.state.modifyTripVisible}
                                changeVisible={this.changeModifyTripVisible.bind(this)}
                            /> 
                        </Row>
                    </Form>
                    
                </Modal>    
        )
    }
}

export default Form.create({name: 'addVisit'})(CreateModal); 