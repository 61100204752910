import React from 'react'
import {Row, Col,Button,Modal,Input,Tree } from 'antd'

const { TreeNode } = Tree;

class CreateModal extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            name:'',
            content:[]
        }
    }
    createAccount= (e) => {
        e.preventDefault();
        this.props.changeVisible(false,{
            name:this.state.name,
            content:this.state.content
        });
        this.setState({
            name:'',
            content:[]
        })
    }
    cancelCreateAccount(){
        this.props.changeVisible(false,'');
        this.setState({
            name:'',
            content:[]
        })
    }
    onSelect = (selectedKeys, info) => {
        console.log('selected', selectedKeys, info);
    };
    
    onCheck = (checkedKeys, info) => {
        this.setState({
            content:checkedKeys
        })
    };
    onChangeResultDate(e) {
        this.setState({
            name:e.target.value
        })
    }
    render() {
        
        return (
            <Modal
                    maskClosable={false}
                    destroyOnClose={true}
                    width={500}
                    visible={this.props.visible}
                    title={"新增管理权限"}
                    onOk={this.createAccount.bind(this)}
                    onCancel={this.cancelCreateAccount.bind(this)}
                    footer={[
                        <Button key="back" onClick={this.cancelCreateAccount.bind(this)}>
                        取消
                        </Button>,
                        <Button key="submit" type="primary" onClick={this.createAccount.bind(this)}>
                        确定
                        </Button>,
                    ]}>
                    <Row>
                        <Col span={24}>
                            <label>权限名称：</label>
                            <Input value={this.state.name}  onChange={this.onChangeResultDate.bind(this)} style={{width:'70%'}} size={'small'}/>
                        </Col>
                        <Col span={24}>
                        <Tree
                            checkable
                            onSelect={this.onSelect.bind(this)}
                            onCheck={this.onCheck.bind(this)}
                        >
                            <TreeNode title="用户管理" key="用户管理"/>
                            <TreeNode title="数据分析" key="数据分析"/>
                            <TreeNode title="首页管理" key="首页管理" >
                                <TreeNode title="展示图设置" key="展示图设置"/>
                                <TreeNode title="官方推荐" key="官方推荐" />
                            </TreeNode>
                            <TreeNode title="艺术体验管理" key="艺术体验管理"/>
                            <TreeNode title="景点管理" key="景点管理"/>
                            <TreeNode title="酒店管理" key="酒店管理"/>
                            <TreeNode title="美食管理" key="美食管理"/>
                            <TreeNode title="购物管理" key="购物管理"/>
                            <TreeNode title="玩乐管理" key="玩乐管理"/>
                            <TreeNode title="交通管理" key="交通管理"/>
                            <TreeNode title="旅行路线管理" key="旅行路线管理"/>
                            <TreeNode title="旅行指南管理" key="旅行指南管理"/>
                            <TreeNode title="标签分类管理" key="标签分类管理"/>
                            <TreeNode title="求助管理" key="求助管理"/>
                            <TreeNode title="管理员管理" key="管理员管理"/>
                            <TreeNode title="后台登录展示图" key="后台登录展示图"/>
                            <TreeNode title="系统安全" key="系统安全"/>
                        </Tree>
                        </Col>
                    </Row>
                </Modal>    
        )
    }
}

export default CreateModal;