import React from 'react'
import {Input, Button, message} from 'antd'
import $ from  'jquery'
class MapAdd extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            
        }
    }
    componentDidMount(){
        let _this=this;
        let id=this.refs.mapContainer
        
        //eslint-disable-next-line
        if(_this.props.lat!==undefined&&_this.props.lng!==undefined){
            //eslint-disable-next-line
            let myLatlngInit = new qq.maps.LatLng(_this.props.lat,_this.props.lng);
            //eslint-disable-next-line
            _this.map = new qq.maps.Map(id, {
                // 地图的中心地理坐标
                center: myLatlngInit,
                zoom: 13
            });
            //eslint-disable-next-line
            _this.markerSearch = new qq.maps.Marker({
                position: myLatlngInit,
                map: _this.map,
            });
        }else{
            // 初始化经纬度，最开始的点
            //eslint-disable-next-line
            let myLatlng = new qq.maps.LatLng(39.95418,-75.16448);
            //eslint-disable-next-line
            this.map = new qq.maps.Map(id, {
                // 地图的中心地理坐标
                center: myLatlng,
                zoom: 10
            });
        }
        // 鼠标点击监听
        //eslint-disable-next-line
        qq.maps.event.addListener(
                 this.map,
                 'click',
                 event=> {
                     // 获取经纬度位置
                     let lat = event.latLng.getLat();
                     let lng = event.latLng.getLng();
                     // 赋值至文本框内
                     _this.props.getlatlng(lat,lng)
                     
                     //eslint-disable-next-line
                     let marker = new qq.maps.Marker({
                         position: event.latLng,
                         map: this.map,
                     });
                     // 添加监听事件   获取鼠标单击事件
                     //eslint-disable-next-line
                     qq.maps.event.addListener(this.map, 'click', function(event) {
                         marker.setMap(null);
                     });
                 }
             );
    }
    //搜索
    searchKeyword = () => {
        let _this=this;
        let id=this.refs.mapContainer
        //获取文本框输入的值
        let keyword = document.getElementById('keyword').value;
        $.ajax({
            url: 'https://apis.map.qq.com/ws/geocoder/v1?address='+keyword+'&key=HWWBZ-43F3P-KBDDB-VHFIC-UXYI3-TRFZC&oversea=1&output=jsonp',
            type: 'GET',
            async:false,
            dataType: 'jsonp',
            jsonp:'callback',
            jsonpCallback:"callback",
            timeout: 5000,
            contentType: 'application/jsonp; charset=utf-8',
            success: function (result){
                if(result.status===0){
                    // 清空上一次搜索结果
                    if(_this.markerSearch!==undefined){ 
                         _this.markerSearch.setMap(null);
                    }
                        _this.props.getlatlng(result.result.location.lat,result.result.location.lng)
                        
                        //eslint-disable-next-line
                        let myLatlng = new qq.maps.LatLng(result.result.location.lat,result.result.location.lng);
                        //eslint-disable-next-line
                        _this.map = new qq.maps.Map(id, {
                            // 地图的中心地理坐标
                            center: myLatlng,
                            zoom: 13
                        });
                         //eslint-disable-next-line
                         _this.markerSearch = new qq.maps.Marker({
                             position: myLatlng,
                             map: _this.map,
                         });
                         
                         //设置标注的名称，当鼠标划过Marker时显示
                         _this.markerSearch.setTitle(result.result.address);
                    
                }else{
                    message.warning('查询无结果！')
                }
            }
        })
    }
   
    render() {
        
        return (
            <div>
                <div>
                　　<Input id="keyword" style={{ width: '250px' }}/>
                　　<Button onClick={this.searchKeyword}>搜索</Button>
                </div>
                <div ref='mapContainer' style={{width:'100%',height:'400px'}}></div>
            </div>
            
        )
    }
}

export default MapAdd;